import React, { useEffect, useReducer, useRef, useState } from "react";
import { useOutletContext, useSearchParams } from "react-router-dom";

import { AuthContext } from "auth/AuthContext";

import {
  ActionIcon,
  Box,
  Button,
  Checkbox,
  Container,
  CopyButton,
  Divider,
  Flex,
  Group,
  NumberInput,
  Select,
  Stack,
  Text,
  TextInput,
  Title,
  Tooltip,
  Paper,
  Alert,
  LoadingOverlay,
} from "@mantine/core";

import {
  IconAlertCircle,
  IconCopy,
  IconCheck,
  IconHelp,
  IconInfoCircle,
} from "@tabler/icons-react";

import {
  checkContractOverBudget,
  createContractReview,
  fetchContractReviewData,
  getCampaigns,
  saveContract,
  sendEmailToCreator,
  validateContractFieldsViaAPI,
} from "components/contracts/common/Api";

import CampaignContract from "components/contracts/contract/CampaignContract";
import { BonusCondition, RepeatCondition } from "components/contracts/contract/CampaignContractComponents";
import { decodeDeliverablesConfigs } from "components/contracts/deliverables/DeliverablesUrlUtils";
import {
  DEFAULT_TITLE_FONT_WEIGHT,
  DEFAULT_TITLE_SIZE,
  ContractStatus,
  DeliverableListActionType,
  ErrorMessage,
  SuccessMessage,
  SUPPORTED_FORMATS_TO_PLATFORMS,
} from "components/contracts/common/Common";
import { invalidEmail, validateEmailList } from "utils/ValidationUtils";
import ContractContactInfo from "components/contracts/contract/ContractContactInfo";
import DeliverableListInput, {
  deliverableListReducer,
} from "components/contracts/contract/DeliverableListInput";
import MultiPlatformInput, {
  initializeMultiPlatformState,
  multiPlatformReducer,
  MultiPlatformActionType,
  platformSelected,
} from "components/contracts/contract/MultiPlatformInput";
import RichTextEditor, { getEditor } from "components/contracts/common/RichTextEditor";
import {
  RecurrenceType,
  RecurrenceFrequency,
  SupportedFormatIntegers,
  SupportedFormatTypes,
  SUPPORTED_PLATFORMS_TO_HANDLES,
  SupportedFormat,
  SupportedPlatform,
} from "models/Common";
import { MaxOfferAction } from "models/Campaign";
import { ContractBrandReview, ContractReviewStatus } from "components/contracts/brand_review/types";

import { toISODateString } from "utils/DateUtils";
import { formatAmount } from "components/contracts/dashboard/Utils";

function SpacedDivider() {
  return <Divider my="sm" />;
}
function CampaignDetailsInput({
  campaignDropdownOptions,
  setCampaignData,
  contractAmountOnChange,
  campaignId,
  creatorId,
  contractAmount,
  hasBonus,
  setHasBonus,
  bonusAmount,
  setBonusAmount,
  bonusCondition,
  setBonusCondition,
  repeatContract,
  setRepeatContract,
  repeatInterval,
  setRepeatInterval,
  repeatFrequency,
  setRepeatFrequency,
  repeatType,
  setRepeatType,
  repeatNumOccurrences,
  setRepeatNumOccurrences,
  brandReview,
  setBrandReview,
  maxOfferCap,
  maxPrice,
  brandMaxPrice,
  recommendPrice,
  setRecommendPrice,
  creatorPrice,
  setCreatorPrice,
  showRejectWarning,
  showWarning,
  isFetching,
  disabled,
  showAdminOptions,
}: {
  campaignDropdownOptions: {
    value: string;
    label: string;
    max_offer_cap: number;
    max_offer_action: number;
  }[];
  setCampaignData: (value: string) => void;
  contractAmountOnChange: (value: number) => void;
  campaignId: string;
  creatorId: string;
  contractAmount: number;
  hasBonus: boolean;
  setHasBonus: (value: boolean) => void;
  bonusAmount: number;
  setBonusAmount: (value: number) => void;
  bonusCondition: string;
  setBonusCondition: (value: string) => void;
  repeatContract: boolean;
  setRepeatContract: (value: boolean) => void;
  repeatInterval: number;
  setRepeatInterval: (value: number) => void;
  repeatFrequency: RecurrenceFrequency;
  setRepeatFrequency: (value: RecurrenceFrequency) => void;
  repeatType: RecurrenceType;
  setRepeatType: (value: RecurrenceType) => void;
  repeatNumOccurrences: number;
  setRepeatNumOccurrences: (value: number) => void;
  brandReview: boolean;
  setBrandReview: (value: boolean) => void;
  maxOfferCap: number;
  maxPrice: number;
  brandMaxPrice?: number;
  recommendPrice: number;
  setRecommendPrice: (value: number) => void;
  creatorPrice: number;
  setCreatorPrice: (value: number) => void;
  showRejectWarning: boolean;
  showWarning: boolean;
  isFetching: boolean;
  disabled: boolean;
  showAdminOptions: boolean;
}) {
  return (
    <>
      <LoadingOverlay visible={isFetching} />
      <Title order={DEFAULT_TITLE_SIZE} fw={DEFAULT_TITLE_FONT_WEIGHT}>
        Campaign Details
      </Title>
      <Box my="sm">
        <Stack>
          <Flex gap="sm">
            <Select
              id="campaign"
              label="Campaign"
              data={campaignDropdownOptions}
              disabled={disabled}
              value={campaignId}
              onChange={(value) => setCampaignData(value)}
              searchable
            />
            <NumberInput
              id="contractAmount"
              value={contractAmount}
              onChange={(value) => contractAmountOnChange(Math.floor(value as number))}
              label="Contract Amount"
              min={1}
              allowDecimal={false}
              prefix="$"
              disabled={disabled}
            />
          </Flex>
          {showRejectWarning && isFetching === false && maxPrice > 0 && (
            <Alert
              variant="light"
              color="red"
              radius="xl"
              title="Contract Requires Review"
              icon={<IconInfoCircle />}>
              {`${
                campaignDropdownOptions.find((c) => c.value === campaignId).label
              } has a Max Offer Cap of $${maxPrice} and action is to reject contracts above this amount.`}
            </Alert>
          )}
          {showWarning && isFetching === false && campaignId && maxPrice > 0 && (
            <Alert
              variant="light"
              color="red"
              radius="xl"
              title="Contract Requires Review"
              icon={<IconInfoCircle />}>
              {`${
                campaignDropdownOptions.find((c) => c.value === campaignId).label
              } has a Max Offer Cap of $${maxPrice}. ${
                brandMaxPrice > maxOfferCap
                  ? `This price cap is from an approved brand review for current creator (creator_id: ${creatorId}).`
                  : ""
              } Contracts at or above this amount require review from brand before approval.`}
            </Alert>
          )}
          <Group gap={5}>
            <Checkbox
              ml="sm"
              label="Contract Review"
              checked={brandReview}
              onChange={(event) => {
                setBrandReview(event.currentTarget.checked);
                if (event.currentTarget.checked) {
                  setRepeatContract(false);
                }
              }}
              disabled={(!showAdminOptions && (showWarning || disabled)) || repeatContract}
            />
            <Tooltip label="If checked, means we will require the brand to review the contract and approve it. Only check this if it was required by the brand.">
              <IconHelp size="12px" />
            </Tooltip>
          </Group>
          {brandReview && (
            <Group>
              <NumberInput
                id="recommendPrice"
                value={recommendPrice}
                onChange={(value) => setRecommendPrice(Math.floor(value as number))}
                label="Recommended Price"
                allowDecimal={false}
                prefix="$"
                disabled={!brandReview}
              />
              <NumberInput
                id="creatorPrice"
                value={creatorPrice}
                onChange={(value) => setCreatorPrice(Math.floor(value as number))}
                label="Creator's Asking Price"
                allowDecimal={false}
                prefix="$"
                disabled={!brandReview}
              />
            </Group>
          )}
          <Checkbox
            ml="sm"
            checked={hasBonus}
            onChange={(event) => {
              setHasBonus(event.currentTarget.checked);
              if (!event.currentTarget.checked) {
                setBonusAmount(null);
                setBonusCondition("");
              }
            }}
            disabled={disabled || brandReview}
            label="Bonus Payment"
          />

          {hasBonus && (
            <Group>
              <NumberInput
                id="bonusAmount"
                value={bonusAmount}
                onChange={(value) => setBonusAmount(Math.floor(value as number))}
                label="Bonus Amount"
                min={1}
                allowDecimal={false}
                prefix="$"
                disabled={disabled || !hasBonus}
              />
              <TextInput
                miw="40%"
                id="bonusCondition"
                onChange={(event) => {
                  setBonusCondition(event.currentTarget.value);
                }}
                value={bonusCondition}
                label="Bonus Condition"
                disabled={disabled || !hasBonus}
              />
            </Group>
          )}
          {hasBonus && <BonusCondition bonusAmount={bonusAmount} bonusCondition={bonusCondition} />}
          <Tooltip disabled={!brandReview} label="Repeat contracts not supported for brand review.">
            <Checkbox
              ml="sm"
              label="Repeat Contract"
              checked={repeatContract}
              onChange={(event) => {
                setRepeatContract(event.currentTarget.checked);
              }}
              disabled={disabled || brandReview}
            />
          </Tooltip>
          {repeatContract && (
            <Stack>
              <Group align="flex-start">
                <NumberInput
                  id="repeatInterval"
                  value={repeatInterval}
                  onChange={(value) => setRepeatInterval(value as number)}
                  label="Interval"
                  allowDecimal={false}
                  disabled={disabled || !repeatContract}
                  error={repeatInterval && repeatInterval < 1 && "Must be greater than 0"}
                />
                <Select
                  label="Frequency"
                  id="repeatFrequency"
                  value={repeatFrequency}
                  onChange={(value) => setRepeatFrequency(value as RecurrenceFrequency)}
                  data={[
                    { value: RecurrenceFrequency.WEEK, label: "Week(s)" },
                    { value: RecurrenceFrequency.MONTH, label: "Month(s)" },
                    { value: RecurrenceFrequency.YEAR, label: "Year(s)" },
                  ]}
                  disabled={disabled || !repeatContract}
                />
                <Select
                  label="Type"
                  id="repeatType"
                  value={repeatType}
                  onChange={(value) => {
                    setRepeatType(value as RecurrenceType);
                    setRepeatNumOccurrences(null);
                  }}
                  data={[
                    { value: RecurrenceType.FIXED, label: "Fixed" },
                    { value: RecurrenceType.INDEFINITE, label: "Indefinite" },
                  ]}
                  disabled={disabled || !repeatContract}
                />
                {repeatType === RecurrenceType.FIXED && (
                  <NumberInput
                    id="repeatNumOccurrences"
                    value={repeatNumOccurrences}
                    onChange={(value) => setRepeatNumOccurrences(value as number)}
                    label="Num Occurrences"
                    min={2}
                    allowDecimal={false}
                    disabled={disabled || !repeatContract}
                    error={
                      repeatNumOccurrences && repeatNumOccurrences < 2 && "Must be greater than 1"
                    }
                  />
                )}
              </Group>
              <RepeatCondition
                repeatInterval={repeatInterval}
                repeatFrequency={repeatFrequency}
                repeatType={repeatType}
                repeatNumOccurrences={repeatNumOccurrences}
              />
            </Stack>
          )}
        </Stack>
      </Box>
    </>
  );
}

function BudgetWarning({
  showBudgetWarning,
  budgetName,
  campaignName,
  currentBudget,
  currentSpend,
  additionalSpend,
}: {
  showBudgetWarning: boolean;
  budgetName: string;
  campaignName: string;
  currentBudget: number;
  currentSpend: number;
  additionalSpend: number;
}) {
  if (!showBudgetWarning) {
    return null;
  }

  return (
    <Alert
      variant="light"
      color="red"
      radius="xl"
      title="Over Budget Warning"
      icon={<IconAlertCircle />}
      mt="sm">
      <Text>
        This contract will exceed the{" "}
        <Text span fw="500">
          {budgetName}
        </Text>{" "}
        budget for{" "}
        <Text span fw="500">
          {campaignName}
        </Text>
        . The current budget is{" "}
        <Text span fw="500">
          {formatAmount(currentBudget)}
        </Text>
        , with{" "}
        <Text span fw="500">
          {formatAmount(currentSpend)}
        </Text>{" "}
        already committed and{" "}
        <Text span fw="500">
          {formatAmount(Math.max(currentBudget - currentSpend, 0))}
        </Text>{" "}
        remaining. Including take rate, this contract will add an additional{" "}
        <Text span fw="500">
          {formatAmount(additionalSpend)}
        </Text>{" "}
        in spend.
      </Text>
    </Alert>
  );
}

function GenerateContractButtons({
  loadingBudget,
  isSavingContract,
  handleGenerateContract,
  handleSaveContract,
  handleSendContractToCreatorEmail,
  disableSendEmail,
  validatedContract,
  hashId,
  emailList,
}: {
  loadingBudget: boolean;
  isSavingContract: boolean;
  handleGenerateContract: () => void;
  handleSaveContract: () => void;
  handleSendContractToCreatorEmail: () => void;
  disableSendEmail: boolean;
  validatedContract: boolean;
  hashId: string;
  emailList: string;
}) {
  const contractUrl = `https://www.1stcollab.com/contracts/${hashId}`;
  return (
    <>
      <Box mb="sm">
        <Flex gap="sm">
          <Button
            color="blue"
            variant="light"
            onClick={handleGenerateContract}
            loading={loadingBudget}>
            Preview Contract
          </Button>
          <Button
            disabled={!validatedContract || loadingBudget}
            color="blue"
            variant="filled"
            onClick={handleSaveContract}
            loading={isSavingContract}>
            Save Contract
          </Button>
          {validatedContract && hashId.length > 0 && (
            <Button
              disabled={disableSendEmail}
              color="green"
              variant="filled"
              onClick={handleSendContractToCreatorEmail}
              loading={isSavingContract}>
              Email Contract to {emailList}
            </Button>
          )}
        </Flex>
      </Box>
      {validatedContract && hashId.length > 0 && (
        <Flex gap="xs" align="flex-end" mb="sm">
          <TextInput id="contractUrl" label="Contract URL" defaultValue={contractUrl} miw={620} />
          <Box pt="lg">
            <CopyButton value={contractUrl} timeout={1000}>
              {({ copied, copy }) => (
                <Tooltip label={copied ? "Copied" : "Copy"} withArrow position="right">
                  <ActionIcon color={copied ? "teal" : "gray"} variant="subtle" onClick={copy}>
                    {copied ? <IconCheck size="1.6rem" /> : <IconCopy size="1.6rem" />}
                  </ActionIcon>
                </Tooltip>
              )}
            </CopyButton>
          </Box>
        </Flex>
      )}
    </>
  );
}

export default function CreateContract({ showAdminOptions }: { showAdminOptions?: boolean }) {
  // User state
  const { user } = useOutletContext<AuthContext>();
  // Search Params
  const [searchParams, setSearchParams] = useSearchParams();

  // POST call state
  const [hashId, setHashId] = useState("");

  // Error message state
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  // set success message
  const [showSuccess, setShowSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  // Disable send email button.
  const [disableSendEmail, setDisableSendEmail] = useState(false);

  // These are set via API calls
  const [campaignDropdownOptions, setCampaignDropdownOptions] = useState([]);
  const [brandName, setBrandName] = useState(null);

  // Editor
  const [editorContent, setEditorContent] = useState("");
  const editor = getEditor("Optional: Add an Addendum to the contract", setEditorContent);

  // Setter function wrapper to require revalidation any time state changes.
  const [validatedContract, setValidatedContract] = useState(false);
  const requireRevalidation =
    <T extends Array<any>, U>(fn: (...args: T) => U) =>
    (...args: T): U => {
      setHashId("");
      setValidatedContract(false);
      setErrorMessage("");
      setShowError(false);
      editor.setEditable(true);
      return fn(...args);
    };

  // Platforms and Creator Handles
  const [multiPlatformState, editMultiPlatformState] = useReducer(
    multiPlatformReducer,
    initializeMultiPlatformState(),
  );
  const editMultiPlatformStateWithRevalidation = requireRevalidation(editMultiPlatformState);

  // Campaign Details
  const [campaignId, setCampaignId] = useState("");
  const [closeContactName, setCloseContactName] = useState("");
  const [contractAmount, setContractAmount] = useState(null);
  const [hasBonus, setHasBonus] = useState(false);
  const [bonusAmount, setBonusAmount] = useState(null);
  const [bonusCondition, setBonusCondition] = useState("");
  const [brandReview, setBrandReview] = useState(false);
  const [maxOfferAction, setMaxOfferAction] = useState<MaxOfferAction>(MaxOfferAction.Review);
  const [maxOfferCap, setMaxOfferCap] = useState<number>(0);

  const [recommendPrice, setRecommendPrice] = useState<number>(0);
  const [creatorPrice, setCreatorPrice] = useState<number>(0);

  const setCampaignIdWithRevalidation = requireRevalidation(setCampaignId);
  const setContractAmountWithRevalidation = requireRevalidation(setContractAmount);

  // Creative Brief
  const [creativeBriefUrl, setCreativeBriefUrl] = useState(null);

  // Creator Details
  const [creatorId, setCreatorId] = useState<string>(null);
  const [creatorFirstName, setCreatorFirstName] = useState("");
  const [creatorEmail, setCreatorEmail] = useState("");
  const setCreatorFirstNameWithRevalidation = requireRevalidation(setCreatorFirstName);
  const setCreatorEmailWithRevalidation = requireRevalidation(setCreatorEmail);

  // Contract Review
  const [contractReviewId, setContractReviewId] = useState(null);
  const [brandMaxPrice, setBrandMaxPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(0);

  // Repeat Contract Settings
  const [repeatContract, setRepeatContract] = useState(false);
  const [repeatInterval, setRepeatInterval] = useState<number>(null);
  const [repeatFrequency, setRepeatFrequency] = useState<RecurrenceFrequency>(null);
  const [repeatType, setRepeatType] = useState<RecurrenceType>(null);
  const [repeatNumOccurrences, setRepeatNumOccurrences] = useState<number>(null);
  const setRepeatContractWithRevalidation = requireRevalidation(setRepeatContract);
  const setRepeatIntervalWithValidation = requireRevalidation(setRepeatInterval);
  const setRepeatFrequencyWithValidation = requireRevalidation(setRepeatFrequency);
  const setRepeatTypeWithRevalidation = requireRevalidation(setRepeatType);
  const setRepeatNumOccurrencesWithRevalidation = requireRevalidation(setRepeatNumOccurrences);

  // Additional Emails
  const [additionalEmails, setAdditionalEmails] = useState<string[]>([]);

  // Deliverables
  const [deliverableList, editDeliverableList] = useReducer(deliverableListReducer, {
    entries: [],
    nextId: 1,
  });
  const editDeliverableListWithRevalidation = requireRevalidation(editDeliverableList);

  // Loading States
  const [isValidatingDeliverable, setIsValidatingDeliverable] = useState(false);
  const [isSavingContract, setIsSavingContract] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  // brand review
  const [showWarning, setShowWarning] = useState(false);
  const [showRejectWarning, setShowRejectWarning] = useState(false);

  // Budget Warning
  const [loadingBudget, setLoadingBudget] = useState(false);
  const [showBudgetWarning, setShowBudgetWarning] = useState(false);
  const [currentBudget, setCurrentBudget] = useState(0);
  const [currentBudgetSpend, setCurrentBudgetSpend] = useState(0);
  const [newSpend, setNewSpend] = useState(0);
  const [currentBudgetName, setCurrentBudgetName] = useState("");
  const [currentCampaignName, setCurrentCampaignName] = useState("");
  const [showBudgetError, setShowBudgetError] = useState(false);
  const [budgetErrorMessage, setBudgetErrorMessage] = useState("");

  // refs
  const fetchEffectRan = useRef(false);
  const effectRan = useRef(false);

  const deliverableTypeToHandleFormat: Record<string, string> = {
    instagram: "https://www.instagram.com/",
    youtube: "https://www.youtube.com/@",
    tiktok: "https://www.tiktok.com/@",
  };

  const setCampaignData = (cid: string) => {
    setCampaignIdWithRevalidation(cid);
    setContractAmountWithRevalidation(0);
    setMaxOfferAction(MaxOfferAction.Review);
    setBrandMaxPrice(0);
    setMaxPrice(0);
    setMaxOfferCap(0);
  };

  const contractAmountOnChange = (value: number) => {
    const selectedCampaign = campaignDropdownOptions.find((c) => c.value === campaignId);

    // reset state if maxoffer has not been set and we have campaignId
    if (!(maxOfferAction || maxOfferCap) && campaignId) {
      setCampaignData(campaignId);
    }
    setContractAmount(value);
  };

  useEffect(() => {
    if (isFetching && !contractAmount) return;
    if (maxPrice > 0 && contractAmount <= maxPrice) {
      setShowRejectWarning(false);
      setShowWarning(false);
      setBrandReview(false);
    } else if (
      maxPrice > 0 &&
      brandMaxPrice > 0 &&
      contractAmount > maxPrice &&
      maxOfferAction === MaxOfferAction.Reject
    ) {
      setShowRejectWarning(true);
      setShowWarning(false);
      setBrandReview(false);
    } else if (
      maxPrice > 0 &&
      brandMaxPrice > 0 &&
      contractAmount > maxPrice &&
      maxOfferAction === MaxOfferAction.Review
    ) {
      setShowRejectWarning(false);
      setBrandReview(true);
      setShowWarning(true);
    } else if (
      maxPrice > 0 &&
      contractAmount >= maxPrice &&
      maxOfferAction === MaxOfferAction.Reject
    ) {
      setShowRejectWarning(true);
      setShowWarning(false);
      setBrandReview(false);
    } else if (
      maxPrice > 0 &&
      contractAmount >= maxPrice &&
      maxOfferAction === MaxOfferAction.Review
    ) {
      setShowRejectWarning(false);
      setBrandReview(true);
      setShowWarning(true);
    } else {
      setShowRejectWarning(false);
      setShowWarning(false);
      setShowWarning(false);
    }
  }, [maxPrice, brandMaxPrice, maxOfferCap, maxOfferAction, contractAmount]);

  useEffect(() => {
    getCampaigns().then((response) => {
      const transformedData = response.campaigns.map(
        ({
          title,
          id,
          max_offer_cap,
          max_offer_action,
        }: {
          title: string;
          id: string;
          max_offer_cap: number;
          max_offer_action: number;
        }) => ({
          value: id.toString(),
          label: title,
          max_offer_cap,
          max_offer_action,
        }),
      );
      setCampaignDropdownOptions(transformedData);
    });
  }, []);

  useEffect(() => {
    // read campaignId from url
    fetchEffectRan.current = true;
    if (!campaignDropdownOptions || campaignDropdownOptions.length === 0) return;
    setIsFetching(true);
    const firstAvailCampaignId = campaignId || searchParams.get("campaignId");
    const selectedCampaign = campaignDropdownOptions.find((c) => c.value === firstAvailCampaignId);
    fetchContractReviewData(firstAvailCampaignId, null, true)
      .then((data) => {
        const contractReviews = data.contract_reviews
          .filter((review: ContractBrandReview) => review.creator_id.toString() === creatorId)
          .sort((a: ContractBrandReview, b: ContractBrandReview) => {
            const aDate = new Date(a.last_updated).getTime();
            const bDate = new Date(b.last_updated).getTime();
            return bDate - aDate;
          });
        setMaxOfferCap(selectedCampaign?.max_offer_cap || maxOfferCap);
        setMaxOfferAction(selectedCampaign?.max_offer_action || maxOfferAction);

        // get the latest approved contract review
        const approvedContractReviews = contractReviews.filter(
          (review: ContractBrandReview) =>
            review.brand_review_status === ContractReviewStatus.APPROVED,
        );
        const latestBrandMaxPrice =
          approvedContractReviews.length > 0 ? approvedContractReviews[0].brand_max_price : 0;
        if (latestBrandMaxPrice > 0) {
          setBrandMaxPrice(latestBrandMaxPrice);
          // if we have a brand approved price
          // take the larger number between campaign cap and brand cap
          setMaxPrice(
            Math.max(selectedCampaign?.max_offer_cap || maxOfferCap, latestBrandMaxPrice),
          );
        }
      })
      .finally(() => {
        setIsFetching(false);
      });
  }, [campaignId, campaignDropdownOptions]);

  useEffect(() => {
    setMaxPrice(Math.max(maxOfferCap, brandMaxPrice));
  }, [maxOfferCap, brandMaxPrice]);

  useEffect(() => {
    if (effectRan.current === true) return;
    // set effectRan to true when called for first time
    effectRan.current = true;
    if (searchParams.get("campaignId") !== null) {
      setCampaignId(searchParams.get("campaignId"));
    }
    if (searchParams.get("brandName") !== null) {
      setBrandName(searchParams.get("brandName"));
    }
    if (searchParams.get("creatorId") !== null) {
      setCreatorId(searchParams.get("creatorId"));
    }
    if (searchParams.get("email") !== null) {
      setCreatorEmail(searchParams.get("email"));
    }
    if (searchParams.get("firstName") !== null) {
      setCreatorFirstName(searchParams.get("firstName"));
    }
    if (searchParams.get("closeContactName") !== null) {
      setCloseContactName(searchParams.get("closeContactName"));
    }
    if (searchParams.get("contractReviewId") !== null) {
      setContractReviewId(searchParams.get("contractReviewId"));
    }
    if (searchParams.get("contractAmount") !== null) {
      setContractAmount(searchParams.get("contractAmount"));
    }
    if (searchParams.get("maxOfferCap") !== null) {
      setMaxOfferCap(Number(searchParams.get("maxOfferCap")));
    }

    // for every platform, check if handles exists in url
    Object.values(SupportedPlatform).forEach((platform) => {
      if (searchParams.get(SUPPORTED_PLATFORMS_TO_HANDLES[platform]) !== null) {
        editMultiPlatformState({
          type: MultiPlatformActionType.UPDATE_HANDLE,
          payload: {
            platform,
            handle: searchParams.get(SUPPORTED_PLATFORMS_TO_HANDLES[platform].toString()),
          },
        });
      }
    });

    // on initial load, check to see if url has deliverables state
    if (searchParams.get("deliverables") !== null) {
      // decode url to get deliverable types
      const decodedDeliverables = decodeDeliverablesConfigs(searchParams.get("deliverables"));
      const deliverableFormat = decodedDeliverables.map((deliverable) => {
        // get format string from integer
        const supportedFormatType = SupportedFormatIntegers[
          deliverable.format
        ] as SupportedFormatTypes;
        const formatString = SupportedFormat[supportedFormatType];

        const includeUsageRights = deliverable.include_usage_rights || false;
        const requiresScriptReview = deliverable.include_script_review || false;
        const requiresVideoReview = deliverable.include_video_review || false;
        const usageRightsDuration = deliverable.usage_rights_duration || 0;

        return {
          format: formatString,
          platform: SUPPORTED_FORMATS_TO_PLATFORMS[formatString],
          requiresScriptReview,
          requiresVideoReview,
          usageRightsDays: usageRightsDuration,
        };
      });
      // when user clicks on generate contract from profile to create contract page
      // read searchParams to update state via deliverableListReducer and DeliverableListActionType.ADD_DELIVERABLE
      // need to do this on first load of page to get deliverableList state from url
      if (deliverableList.entries.length === 0) {
        if (deliverableFormat.length > 0) {
          deliverableFormat.forEach((deliverable) => {
            const id = deliverableList.nextId;
            if (searchParams.get(SUPPORTED_PLATFORMS_TO_HANDLES[deliverable.platform].toString())) {
              const {
                platform,
                format,
                requiresScriptReview,
                requiresVideoReview,
                usageRightsDays,
              } = deliverable;
              const creatorHandle = searchParams
                .get(SUPPORTED_PLATFORMS_TO_HANDLES[platform].toString())
                .split("@")[1];
              const creatorIdfromURL = searchParams.get("creatorId");
              const profileLink = deliverableTypeToHandleFormat[platform].concat(creatorHandle);

              // update multiple platforms state with details from url
              editMultiPlatformState({
                type: MultiPlatformActionType.ADD_API_DATA,
                payload: {
                  platform: platform as SupportedPlatform,
                  profileLink,
                  creatorId: creatorIdfromURL,
                },
              });

              // update multiple platforms state selected set
              // editMultiPlatformState({
              //   type: MultiPlatformActionType.SET_SELECTED,
              //   payload: {
              //     platform,
              //     selected: true,
              //   },
              // });

              // add deliverable with details of state from url
              editDeliverableList({
                type: DeliverableListActionType.ADD_DELIVERABLE_WITH_DETAILS,
                payload: {
                  id,
                  format,
                  platform,
                  creatorHandle,
                  profileLink,
                  usageRightsDays,
                  requiresScriptReview,
                  requiresVideoReview,
                },
              });
            }
          });
        }
      }
    }
  }, []);

  useEffect(() => {
    if (campaignDropdownOptions && campaignDropdownOptions.length > 0) {
      if (searchParams.get("campaignId") !== null) {
        setCampaignId(searchParams.get("campaignId"));
        setCampaignData(searchParams.get("campaignId"));
      }
      if (searchParams.get("contractAmount") !== null) {
        contractAmountOnChange(Number(searchParams.get("contractAmount")));
      }
      if (searchParams.get("recommendPrice") !== null) {
        setRecommendPrice(Number(searchParams.get("recommendPrice")));
      }
      if (searchParams.get("hasBonus") !== null) {
        setHasBonus(searchParams.get("hasBonus") === "true");
      }
      if (searchParams.get("bonusAmount") !== null) {
        setBonusAmount(Number(searchParams.get("bonusAmount")));
      }
      if (searchParams.get("bonusCondition") !== null) {
        setBonusCondition(searchParams.get("bonusCondition"));
      }
      if (editor && searchParams.get("addendum") !== null) {
        editor.commands.setContent(JSON.parse(searchParams.get("addendum")));
      }
      Object.values(SupportedPlatform).forEach((platform) => {
        if (searchParams.get(platform) !== null) {
          editMultiPlatformState({
            type: MultiPlatformActionType.SET_SELECTED,
            payload: { platform, selected: searchParams.get(platform.toString()) === "true" },
          });
        }
      });
    }
  }, [campaignDropdownOptions, editor]);

  useEffect(() => {
    if (multiPlatformState && campaignDropdownOptions && campaignDropdownOptions.length > 0) {
      const updatedSearchParams = new URLSearchParams(searchParams.toString());
      if (recommendPrice) {
        updatedSearchParams.set("recommendPrice", recommendPrice.toString());
      }
      if (campaignId) {
        updatedSearchParams.set("campaignId", campaignId.toString());
      } else {
        updatedSearchParams.delete("campaignId");
      }
      if (contractAmount) {
        updatedSearchParams.set("contractAmount", contractAmount.toString());
      } else {
        updatedSearchParams.delete("contractAmount");
      }
      if (hasBonus) {
        updatedSearchParams.set("hasBonus", hasBonus.toString());
        if (bonusAmount) {
          updatedSearchParams.set("bonusAmount", bonusAmount.toString());
        }
        if (bonusCondition) {
          updatedSearchParams.set("bonusCondition", bonusCondition);
        }
      } else {
        updatedSearchParams.delete("hasBonus");
        updatedSearchParams.delete("bonusAmount");
        updatedSearchParams.delete("bonusCondition");
      }
      if (editorContent) {
        updatedSearchParams.set("addendum", editorContent);
      } else {
        updatedSearchParams.delete("addendum");
      }
      if (creatorEmail) {
        updatedSearchParams.set("email", creatorEmail);
      } else {
        updatedSearchParams.delete("addendum");
      }
      if (closeContactName) {
        updatedSearchParams.set("closeContactName", closeContactName);
      } else {
        updatedSearchParams.delete("closeContactName");
      }

      if (multiPlatformState.instagram.selected) {
        updatedSearchParams.set(
          SupportedPlatform.INSTAGRAM.toString(),
          multiPlatformState.instagram.selected.toString(),
        );
      } else {
        updatedSearchParams.delete(SupportedPlatform.INSTAGRAM.toString());
      }
      if (multiPlatformState.tiktok.selected) {
        updatedSearchParams.set(
          SupportedPlatform.TIKTOK.toString(),
          multiPlatformState.tiktok.selected.toString(),
        );
      } else {
        updatedSearchParams.delete(SupportedPlatform.TIKTOK.toString());
      }
      if (multiPlatformState.youtube.selected) {
        updatedSearchParams.set(
          SupportedPlatform.YOUTUBE.toString(),
          multiPlatformState.youtube.selected.toString(),
        );
      } else {
        updatedSearchParams.delete(SupportedPlatform.YOUTUBE.toString());
      }
      if (multiPlatformState.instagram.handle) {
        updatedSearchParams.set(
          SUPPORTED_PLATFORMS_TO_HANDLES.instagram,
          multiPlatformState.instagram.handle.toString(),
        );
      } else {
        updatedSearchParams.delete(SUPPORTED_PLATFORMS_TO_HANDLES.instagram);
      }
      if (multiPlatformState.tiktok.handle) {
        updatedSearchParams.set(
          SUPPORTED_PLATFORMS_TO_HANDLES.tiktok,
          multiPlatformState.tiktok.handle.toString(),
        );
      } else {
        updatedSearchParams.delete(SUPPORTED_PLATFORMS_TO_HANDLES.tiktok);
      }
      if (multiPlatformState.youtube.handle) {
        updatedSearchParams.set(
          SUPPORTED_PLATFORMS_TO_HANDLES.youtube,
          multiPlatformState.youtube.handle.toString(),
        );
      } else {
        updatedSearchParams.delete(SUPPORTED_PLATFORMS_TO_HANDLES.youtube);
      }
      setSearchParams(updatedSearchParams.toString());
    }
  }, [
    campaignId,
    contractAmount,
    hasBonus,
    bonusAmount,
    bonusCondition,
    recommendPrice,
    editorContent,
    creatorEmail,
    closeContactName,
    multiPlatformState.instagram.selected,
    multiPlatformState.tiktok.selected,
    multiPlatformState.youtube.selected,
    multiPlatformState.youtube.handle,
    multiPlatformState.instagram.handle,
    multiPlatformState.tiktok.handle,
  ]);

  const addDeliverableWithValidation = () => {
    if (deliverableList.entries.length === 0) {
      let valid = campaignId !== "" && contractAmount > 0;
      if (!valid) {
        setShowError(true);
        setErrorMessage("ERROR: Please select a campaign and specify a contract amount.");
        return;
      }

      // Validate that at least one platform is selected and that all selected platforms have a handle.
      valid = valid && Object.entries(multiPlatformState).some(([_, { selected }]) => selected);
      valid =
        valid &&
        Object.entries(multiPlatformState).every(
          ([_, { selected, handle }]) => !selected || handle.length > 0,
        );
      if (!valid) {
        setShowError(true);
        setErrorMessage(
          "ERROR: Please select at least one platform and specify the creator's handle.",
        );
        return;
      }

      // Validate repeat fields.
      valid =
        valid &&
        (!repeatContract ||
          (repeatInterval > 0 &&
            repeatFrequency != null &&
            ((repeatType === RecurrenceType.FIXED && repeatNumOccurrences > 1) ||
              repeatType === RecurrenceType.INDEFINITE)));
      if (!valid) {
        setShowError(true);
        setErrorMessage("ERROR: Please fill out all fields for repeat contract.");
        return;
      }

      // Show spinner while API call is in progress.
      setIsValidatingDeliverable(true);

      // Make an API call to validate that the campaign ID and all creator handles exist in the DB.
      // Additionally, update the brand name and update MultiPlatformState with all API data for each creator handle.

      // we should validate this on generate contract button click on profile page if we are coming from creator profile
      validateContractFieldsViaAPI(multiPlatformState, campaignId)
        .then((response) => {
          const { success, error } = response;

          if (success) {
            setBrandName(response.data.brand_name);
            setCreativeBriefUrl(response.data.creative_brief_url);
            setCreatorEmail(response.data.creator_email);
            setCreatorFirstName(response.data.creator_first_name);
            setCloseContactName(response.data.close_contact_name);
            Object.values(SupportedPlatform).forEach((platform) => {
              if (platform.toLowerCase() in response.data) {
                const apiData = response.data[platform.toLowerCase()];
                editMultiPlatformState({
                  type: MultiPlatformActionType.ADD_API_DATA,
                  payload: {
                    platform: platform as SupportedPlatform,
                    profileLink: apiData.profile_link,
                    creatorId: apiData.creator_id,
                  },
                });
              }
            });

            // Add a blank deliverable to the list.
            editDeliverableList({
              type: DeliverableListActionType.ADD_DELIVERABLE,
              payload: {},
            });

            setShowError(false);
            setErrorMessage("");
            setIsValidatingDeliverable(false);
          } else {
            setShowError(true);
            setErrorMessage(`ERROR: ${error}`);
            setIsValidatingDeliverable(false);
          }
        })
        .catch((e) => {
          setShowError(true);
          setErrorMessage(`ERROR: ${e.message}`);
          setIsValidatingDeliverable(false);
        });
    } else {
      editDeliverableList({
        type: DeliverableListActionType.ADD_DELIVERABLE,
        payload: {},
      });
    }
  };

  const validateDeliverables = () => {
    const valid =
      deliverableList.entries.length > 0 &&
      deliverableList.entries.every(({ deliverable }) => deliverable.validate());
    // Show error message if fails client-side validation
    if (!valid) {
      const invalidDeliverableIndex = deliverableList.entries.findIndex(
        ({ deliverable }) => !deliverable.validate(),
      );
      setValidatedContract(false);
      setShowError(true);
      setErrorMessage(
        `ERROR: Deliverable #${
          invalidDeliverableIndex + 1
        } is invalid. Please fill out all fields and ensure that dates are in chronological order. Video and Draft Due Dates cannot be today or in the past.`,
      );
      return false;
    }
    setShowError(false);
    setErrorMessage("");
    setValidatedContract(true);
    return true;
  };

  const validateDeliverablesAndGenerateContract = () => {
    // Validate that an employee is set.
    let valid = closeContactName.length > 0;
    if (!valid) {
      setShowError(true);
      setErrorMessage("ERROR: Creator's Contact Email is empty. Please input something.");
      return;
    }

    valid = valid && !invalidEmail(creatorEmail);
    if (!valid) {
      setShowError(true);
      setErrorMessage("ERROR: Please input the creator's email address.");
      return;
    }
    valid = valid && validateEmailList(additionalEmails);
    if (!valid) {
      setShowError(true);
      setErrorMessage("ERROR: Invalid Email Addresses was added, please remove it.");
      return;
    }

    if (validateDeliverables()) {
      const contractDate = toISODateString(
        new Date(
          Math.max(
            ...deliverableList.entries.map(({ deliverable }) =>
              deliverable.timeline.liveDate.getTime(),
            ),
          ),
        ),
      );
      setLoadingBudget(true);
      setShowBudgetWarning(false);
      checkContractOverBudget({
        campaignId,
        contractAmount: contractAmount * 100,
        contractDate,
      })
        .then((response) => {
          const {
            success,
            budget,
            contractSpend,
            currentSpend,
            budgetName,
            campaignName,
            overBudget,
          } = response;
          if (success) {
            setCurrentBudget(budget);
            setNewSpend(contractSpend);
            setCurrentBudgetSpend(currentSpend);
            setCurrentBudgetName(budgetName);
            setShowBudgetWarning(overBudget);
            setCurrentCampaignName(campaignName);
          } else {
            setShowBudgetError(true);
            setBudgetErrorMessage("Failed to retrieve budget information.");
          }
        })
        .catch((e) => {
          setShowBudgetError(true);
          setBudgetErrorMessage(`ERROR: ${e.message}`);
        })
        .finally(() => setLoadingBudget(false));
    }
  };

  const handleSendContractToCreatorEmail = () => {
    setShowSuccess(false);
    setShowError(false);
    setDisableSendEmail(true);
    sendEmailToCreator(closeContactName, creatorEmail, creatorFirstName, hashId).then(
      (response) => {
        if (response.success) {
          setSuccessMessage(`Email to ${creatorEmail} has been sent!`);
          setShowSuccess(true);
        } else {
          setErrorMessage(`Failed to send email to ${creatorEmail}.`);
          setShowError(true);
          setDisableSendEmail(false);
        }
      },
    );
  };

  const validateContractReview = () => {
    let valid = recommendPrice >= 1;
    if (!valid) {
      setShowError(true);
      setErrorMessage("ERROR: Recommended Price needs to be above 1$. Please input something.");
      return false;
    }

    valid = creatorPrice >= 1;
    if (!valid) {
      setShowError(true);
      setErrorMessage("ERROR: Creator Asking Price needs to be above 1$. Please input something.");
      return false;
    }

    setShowSuccess(false);

    return validateDeliverables();
  };

  const handleCreateContractReview = () => {
    if (!validateContractReview()) {
      return;
    }

    setIsSavingContract(true);
    editor.setEditable(false);
    createContractReview(
      campaignId,
      multiPlatformState,
      deliverableList.entries.map(({ deliverable }) => deliverable),
      recommendPrice,
      creatorPrice,
      contractAmount,
    )
      .then((response) => {
        if (response.success) {
          const url = `/campaigns/${response.campaign_id}/${response.ad_group_id}/contract_approval`;
          setSuccessMessage(
            `Successfully created a Contract Review! <a href=${url} target="_blank">View here</a>`,
          );
          setShowSuccess(true);
          setShowError(false);
          setErrorMessage("");
          setIsSavingContract(false);
        } else {
          setShowError(true);
          setErrorMessage(`ERROR: ${response.result.error}`);
          setIsSavingContract(false);
          editor.setEditable(true);
        }
      })
      .catch((e) => {
        editor.setEditable(true);
        setShowError(true);
        setErrorMessage(`ERROR: ${e.message}`);
        setIsSavingContract(false);
      });
  };
  const handleSaveContract = () => {
    setIsSavingContract(true);
    editor.setEditable(false);

    saveContract(
      repeatContract,
      repeatInterval,
      repeatFrequency,
      repeatType,
      repeatNumOccurrences,
      campaignId,
      contractAmount,
      bonusAmount,
      bonusCondition,
      creatorFirstName,
      creatorEmail,
      additionalEmails,
      multiPlatformState,
      deliverableList.entries.map(({ deliverable }) => deliverable),
      closeContactName,
      editor.isEmpty ? "" : editor.getHTML(),
      contractReviewId,
    )
      .then((response) => {
        if (response.success) {
          setHashId(response.hash_id);
          setShowError(false);
          setErrorMessage("");
          setIsSavingContract(false);
        } else {
          setShowError(true);
          setErrorMessage(`ERROR: ${response.error ?? response.result.error}`);
          setIsSavingContract(false);
          editor.setEditable(true);
        }
      })
      .catch((e) => {
        editor.setEditable(true);
        setShowError(true);
        setErrorMessage(`ERROR: ${e.error}`);
        setIsSavingContract(false);
      });
  };

  return (
    <Paper p="xl">
      <CampaignDetailsInput
        campaignDropdownOptions={campaignDropdownOptions}
        setCampaignData={setCampaignData}
        contractAmountOnChange={contractAmountOnChange}
        campaignId={campaignId}
        creatorId={creatorId}
        contractAmount={contractAmount}
        hasBonus={hasBonus}
        setHasBonus={setHasBonus}
        bonusAmount={bonusAmount}
        setBonusAmount={setBonusAmount}
        bonusCondition={bonusCondition}
        setBonusCondition={setBonusCondition}
        disabled={deliverableList.entries.length > 0}
        repeatContract={repeatContract}
        setRepeatContract={setRepeatContractWithRevalidation}
        repeatInterval={repeatInterval}
        setRepeatInterval={setRepeatIntervalWithValidation}
        repeatFrequency={repeatFrequency}
        setRepeatFrequency={setRepeatFrequencyWithValidation}
        repeatType={repeatType}
        setRepeatType={setRepeatTypeWithRevalidation}
        repeatNumOccurrences={repeatNumOccurrences}
        setRepeatNumOccurrences={setRepeatNumOccurrencesWithRevalidation}
        brandReview={brandReview}
        setBrandReview={setBrandReview}
        maxOfferCap={maxOfferCap}
        maxPrice={maxPrice}
        brandMaxPrice={brandMaxPrice}
        recommendPrice={recommendPrice}
        setRecommendPrice={setRecommendPrice}
        creatorPrice={creatorPrice}
        setCreatorPrice={setCreatorPrice}
        showRejectWarning={showRejectWarning}
        showWarning={showWarning}
        showAdminOptions={showAdminOptions}
        isFetching={isFetching}
      />
      <SpacedDivider />
      <MultiPlatformInput
        multiPlatformState={multiPlatformState}
        editMultiPlatformState={editMultiPlatformStateWithRevalidation}
        disabled={deliverableList.entries.length > 0}
      />
      <SpacedDivider />
      {platformSelected(multiPlatformState) && (
        <>
          <DeliverableListInput
            multiPlatformState={multiPlatformState}
            deliverableList={deliverableList}
            editDeliverableList={editDeliverableListWithRevalidation}
            addDeliverableWithValidation={addDeliverableWithValidation}
            isValidatingDeliverable={isValidatingDeliverable}
          />
          <SpacedDivider />
        </>
      )}
      {!brandReview && platformSelected(multiPlatformState) && (
        <Stack mb="sm">
          <Group grow maw={650}>
            <RichTextEditor editor={editor} />
          </Group>
          <Divider />
        </Stack>
      )}
      {showError && <ErrorMessage errorMessage={errorMessage} />}
      {showSuccess && <SuccessMessage message={successMessage} />}
      {brandReview && deliverableList.entries.length > 0 && (
        <Box mb="sm">
          <Flex gap="sm">
            <Button
              disabled={showSuccess}
              color="blue"
              variant="filled"
              onClick={handleCreateContractReview}
              loading={isSavingContract}>
              Create Contract Review
            </Button>
          </Flex>
        </Box>
      )}
      {!brandReview && deliverableList.entries.length > 0 && (
        <>
          <ContractContactInfo
            creatorFirstName={creatorFirstName}
            creatorEmail={creatorEmail}
            closeContactName={closeContactName}
            additionalEmails={additionalEmails}
            setCreatorFirstName={setCreatorFirstNameWithRevalidation}
            setCreatorEmail={setCreatorEmailWithRevalidation}
            setCloseContactName={setCloseContactName}
            setAdditionalEmails={setAdditionalEmails}
            disabled={validatedContract}
          />
          <SpacedDivider />
          <GenerateContractButtons
            loadingBudget={loadingBudget}
            isSavingContract={isSavingContract}
            handleGenerateContract={validateDeliverablesAndGenerateContract}
            handleSaveContract={handleSaveContract}
            disableSendEmail={disableSendEmail}
            handleSendContractToCreatorEmail={handleSendContractToCreatorEmail}
            validatedContract={validatedContract}
            hashId={hashId}
            emailList={
              additionalEmails.length > 0 ? `${creatorEmail}, ${additionalEmails}` : creatorEmail
            }
          />
          <BudgetWarning
            showBudgetWarning={showBudgetWarning}
            currentBudget={currentBudget}
            currentSpend={currentBudgetSpend}
            additionalSpend={newSpend}
            budgetName={currentBudgetName}
            campaignName={currentCampaignName}
          />
          <SpacedDivider />
          {validatedContract && (
            <Container>
              <Paper shadow="lg" radius="sm" p="md" withBorder>
                <CampaignContract
                  contractStatus={ContractStatus.CREATED}
                  creatorFirstName={creatorFirstName}
                  brandName={brandName}
                  contractAmount={contractAmount}
                  bonusAmount={bonusAmount}
                  bonusCondition={bonusCondition}
                  deliverables={deliverableList.entries.map(({ deliverable }) => deliverable)}
                  updateDeliverableTimeline={null}
                  addendum={editor.isEmpty ? "" : editor.getHTML()}
                  creativeBriefUrl={creativeBriefUrl}
                  repeatContract={repeatContract}
                  repeatInterval={repeatInterval}
                  repeatFrequency={repeatFrequency}
                  repeatType={repeatType}
                  repeatNumOccurrences={repeatNumOccurrences}
                />
              </Paper>
            </Container>
          )}
        </>
      )}
    </Paper>
  );
}
