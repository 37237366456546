import React, { useEffect, useState } from "react";

import {
  Button,
  Center,
  Flex,
  Group,
  Loader,
  Modal,
  Pagination,
  Stack,
  Text,
  TextInput,
} from "@mantine/core";

import { useDisclosure, useInputState } from "@mantine/hooks";
import { Notifications } from "@mantine/notifications";

import { IconPlus } from "@tabler/icons-react";

import { showFailureNotification, showSuccessNotification } from "components/common/Notifications";

import { fetchCreatorInfoFromLink } from "campaigns/api/fetchCreatorInfoFromLink";
import { getExistingBrandPartners } from "components/creator_sets/api/CreatorSetsApi";
import {
  addCreatorToExistingPartners,
  fetchCreatorSetDetails,
} from "components/creator_sets/CreatorSetUtils";

import UnifiedCreatorRep from "components/creator_lists/unified_creator_rep/UnifiedCreatorRep";
import { CreatorDetails } from "components/discovery/Datamodels";
import { chunk } from "utils/PaginationUtils";

import { BaseAddCreatorDetails } from "components/campaign/dashboard/CreatorDetailsForms";

const DEFAULT_PAGE_SIZE = 25;

const AddCreatorModalToExistingPartners = ({
  existingPartnerCreatorIds,
  setExistingPartnerCreatorIds,
  setPage,
}: {
  existingPartnerCreatorIds: number[];
  setExistingPartnerCreatorIds: (creatorIds: number[]) => void;
  setPage: (page: number) => void;
}) => {
  const [opened, { open, close }] = useDisclosure(false);
  const [hidden, setHidden] = useState(true);
  const [linkUrl, setLinkUrl] = useInputState("");
  const [youtubeHandle, setYoutubeHandle] = useInputState("");
  const [tiktokHandle, setTiktokHandle] = useInputState("");
  const [instagramHandle, setInstagramHandle] = useInputState("");
  const [firstName, setFirstName] = useInputState("");
  const [lastName, setLastName] = useInputState("");
  const [email, setEmail] = useInputState("");

  const [buttonLoading, setButtonLoading] = useState(false);
  const [addToExistingPartnersLoading, setAddToExistingPartnersLoading] = useState(false);

  useEffect(() => {
    if (!opened) {
      setLinkUrl("");
      setYoutubeHandle("");
      setTiktokHandle("");
      setInstagramHandle("");
      setFirstName("");
      setLastName("");
      setEmail("");
      setHidden(true);
    }
  }, [opened]);

  const handleAddCreatorToExistingPartners = () => {
    setAddToExistingPartnersLoading(true);
    addCreatorToExistingPartners({
      youtubeHandle,
      instagramHandle,
      tiktokHandle,
      firstName,
      lastName,
      email,
    })
      .then((response) => {
        if (response != null && response.success) {
          if (existingPartnerCreatorIds.includes(response.creatorId)) {
            showSuccessNotification({
              message: "Creator already added to existing partners.",
            });
          } else {
            setExistingPartnerCreatorIds([response.creatorId, ...existingPartnerCreatorIds]);
            setPage(1);
            showSuccessNotification({
              message: "Successfully added creator to existing partners.",
            });
          }
          close();
        } else {
          showFailureNotification({ message: "Error adding creator to existing partners." });
        }
      })
      .finally(() => {
        setAddToExistingPartnersLoading(false);
      });
  };

  return (
    <>
      <Modal
        size="lg"
        opened={opened}
        onClose={() => {
          close();
          setHidden(true);
        }}
        title={
          <Text fw="600" size="xl">
            Add Creator to Existing Partners
          </Text>
        }>
        <Flex align="flex-end" gap="sm" justify="center">
          <TextInput
            placeholder="https://www.instagram.com/eleanor_the_pug"
            label="Profile URL"
            description="Enter the profile URL of the creator to automatically import the creator details."
            value={linkUrl}
            onChange={(event) => setLinkUrl(event.currentTarget.value)}
            size="sm"
            styles={{
              root: {
                minWidth: "80%",
              },
            }}
          />
          <Button
            loading={buttonLoading}
            onClick={() => {
              setButtonLoading(true);
              setHidden(true);
              fetchCreatorInfoFromLink(linkUrl)
                .then((data) => {
                  const { creator } = data;
                  if (creator != null) {
                    if (creator.youtube_handle != null) {
                      setYoutubeHandle(creator.youtube_handle);
                    }
                    if (creator.tiktok_handle != null) {
                      setTiktokHandle(creator.tiktok_handle);
                    }
                    if (creator.instagram_handle != null) {
                      setInstagramHandle(creator.instagram_handle);
                    }
                    if (creator.first_name != null) {
                      setFirstName(creator.first_name);
                    }
                    if (creator.last_name != null) {
                      setLastName(creator.last_name);
                    }
                    if (creator.email != null) {
                      setEmail(creator.email);
                    }
                    setHidden(false);
                  }
                })
                .finally(() => {
                  setButtonLoading(false);
                });
            }}>
            Import
          </Button>
        </Flex>
        {!hidden && (
          <>
            <BaseAddCreatorDetails
              youtubeHandle={youtubeHandle}
              setYoutubeHandle={setYoutubeHandle}
              tiktokHandle={tiktokHandle}
              setTiktokHandle={setTiktokHandle}
              instagramHandle={instagramHandle}
              setInstagramHandle={setInstagramHandle}
              firstName={firstName}
              setFirstName={setFirstName}
              lastName={lastName}
              setLastName={setLastName}
              email={email}
              setEmail={setEmail}
            />
            <Group justify="right">
              <Button
                loading={addToExistingPartnersLoading}
                leftSection={<IconPlus size="1rem" />}
                onClick={handleAddCreatorToExistingPartners}>
                Add to Existing Partners
              </Button>
            </Group>
          </>
        )}
      </Modal>
      <Button leftSection={<IconPlus size="1rem" />} onClick={open}>
        Import Creator
      </Button>
    </>
  );
};

export const BrandExistingPartnersView = () => {
  const [existingPartnerCreatorIds, setExistingPartnerCreatorIds] = useState([]);
  const [activePage, setPage] = useState(1);
  const [creatorIdChunks, setCreatorIdChunks] = useState([]);
  const [numPages, setNumPages] = useState(0);
  const [currChunk, setCurrChunk] = useState([]);
  const [creatorDetailResults, setCreatorDetailResults] = useState<Record<number, CreatorDetails>>(
    {},
  );
  const [loading, setLoading] = useState(true);
  const [pageLoading, setPageLoading] = useState(false);

  // Fetch existing partner list
  useEffect(() => {
    const abortController = new AbortController();
    setLoading(true);
    getExistingBrandPartners(abortController)
      .then((response) => {
        if (response?.existing_partners) {
          setExistingPartnerCreatorIds(response.existing_partners);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  // Paginate existing partner creator ids
  useEffect(() => {
    setCreatorIdChunks(chunk(existingPartnerCreatorIds, DEFAULT_PAGE_SIZE));
    setNumPages(Math.ceil(existingPartnerCreatorIds.length / DEFAULT_PAGE_SIZE));
  }, [existingPartnerCreatorIds]);

  // Set current page
  useEffect(() => {
    setCurrChunk(creatorIdChunks[activePage - 1]);
  }, [creatorIdChunks, activePage]);

  // Fetch details for current page
  useEffect(() => {
    const abortController = new AbortController();
    if (currChunk?.length > 0) {
      setPageLoading(true);
      fetchCreatorSetDetails(currChunk, {}, setCreatorDetailResults, abortController).finally(
        () => {
          setPageLoading(false);
        },
      );
    }
  }, [currChunk]);

  const isLoading = loading || pageLoading;
  
  return (
    <>
      <Notifications />
      <Stack>
        <Group justify="right">
          <AddCreatorModalToExistingPartners
            existingPartnerCreatorIds={existingPartnerCreatorIds}
            setExistingPartnerCreatorIds={setExistingPartnerCreatorIds}
            setPage={setPage}
          />
        </Group>
        {isLoading && (
          <Center mt="xs">
            <Loader />
          </Center>
        )}
        {!isLoading && (
          <>
            {currChunk &&
              currChunk.map((creatorId) => {
                if (!creatorDetailResults[creatorId]) {
                  return null;
                }
                return (
                  <UnifiedCreatorRep
                    creator={creatorDetailResults[creatorId]}
                    key={`entry-${creatorId}`}
                    defaultExpandedPlatforms={[]}
                    archetype="Existing Partner"
                  />
                );
              })}
            <Center>
              <Pagination value={activePage} onChange={setPage} total={numPages} />
            </Center>
          </>
        )}
      </Stack>
    </>
  );
};

export default BrandExistingPartnersView;
