import React, { useRef } from "react";

import { Anchor, Box, Container, List, Text } from "@mantine/core";

import { ContractStatus } from "components/contracts/common/Common";

import {
  Addendum,
  CompensationSection,
  DEFAULT_LIST_SPACING,
  DEFAULT_SECTION_PADDING,
  FW_BOLD,
  RepeatCondition,
  SingleDeliverableLiveDate,
  SpacedDivider,
  TermsSection,
} from "components/contracts/contract/CampaignContractComponents";
import DeliverableTimeline from "components/contracts/contract/DeliverableTimeline";
import header from "components/contracts/contract/TitleHeader";
import Deliverable from "components/contracts/models/Deliverable";

import {
  SUPPORTED_PLATFORMS_TO_DISPLAY_NAMES,
  SupportedPlatform,
  RecurrenceFrequency,
  RecurrenceType,
} from "models/Common";

const UsageRightsCampaignSection = ({
  contractStatus,
  brandName,
  sortOrder,
  deliverables,
  updateDeliverableTimeline,
}: {
  contractStatus: ContractStatus;
  brandName: string;
  sortOrder: number[];
  deliverables: Deliverable[];
  updateDeliverableTimeline: (deliverableId: string, newTimeline: DeliverableTimeline) => void;
}) => {
  const liveDateText = (
    <SingleDeliverableLiveDate
      contractStatus={contractStatus}
      brandName={brandName}
      liveDateHeading="Deliverable Due Date: "
      deliverable={deliverables[sortOrder[0]]}
      updateDeliverableTimeline={updateDeliverableTimeline}
    />
  );

  return (
    <Box>
      {header("Campaign", 3)}
      <Container mx="xs">
        <List type="unordered" spacing={DEFAULT_LIST_SPACING}>
          <List.Item>
            <Text span fw={FW_BOLD}>
              Brand:{" "}
            </Text>
            <Text span>{brandName}</Text>
          </List.Item>
          <List.Item>{liveDateText}</List.Item>
        </List>
      </Container>
    </Box>
  );
};

const DeliverableDetails = ({
  deliverable,
  creatorFirstName,
}: {
  deliverable: Deliverable;
  creatorFirstName: string;
}) => {
  const platformName = SUPPORTED_PLATFORMS_TO_DISPLAY_NAMES[deliverable.platform];
  let codeName = "";
  let helpLink = null;
  switch (deliverable.platform) {
    case SupportedPlatform.INSTAGRAM:
      codeName = "partnership ad code";
      helpLink = (
        <Text span>
          If you need help with generating the Instagram partnership ad code, take a look at our
          guide{" "}
          <Anchor
            href="https://help.1stcollab.com/en/articles/9493801-i-m-being-asked-to-submit-an-instagram-partnership-ad-code-how-do-i-do-that"
            target="_blank">
            here
          </Anchor>
          .
        </Text>
      );
      break;
    case SupportedPlatform.TIKTOK:
      codeName = "spark code";
      helpLink = (
        <Text span>
          If you need help with generating the TikTok spark code, take a look at our guide{" "}
          <Anchor
            href="https://help.1stcollab.com/en/articles/9493798-i-m-being-asked-to-submit-a-tiktok-spark-code-how-do-i-do-that"
            target="_blank">
            here
          </Anchor>
          .
        </Text>
      );
      break;
    default:
      codeName = "partnership code";
      break;
  }

  return (
    <>
      <Box p={DEFAULT_SECTION_PADDING}>
        {header("Deliverables", 3)}
        <Container mx="xs">
          <List type="unordered" spacing={DEFAULT_LIST_SPACING}>
            <List.Item>
              <Text span>
                High-quality copy of the video that was used to create the post available at:{" "}
                <Anchor href={deliverable.liveContentUrl} target="_blank">
                  {deliverable.liveContentUrl}
                </Anchor>
              </Text>
              <List type="unordered" spacing={DEFAULT_LIST_SPACING}>
                <List.Item>
                  <Text span>
                    Please submit the highest-quality copy of this video that you have available.
                  </Text>
                </List.Item>
                <List.Item>
                  <Text span>
                    Video should exactly match the video that you posted (for example, it should
                    include your edits), EXCEPT:
                  </Text>
                  <List type="unordered" spacing={DEFAULT_LIST_SPACING}>
                    <List.Item>
                      <Text span>
                        If you used copyrighted music, please submit a copy of the video without the
                        music.
                      </Text>
                    </List.Item>
                    <List.Item>
                      <Text span>
                        If you used subtitles, text-to-speech voiceovers, or other special effects
                        within the Instagram editor, please submit a copy of the video without those
                        effects.
                      </Text>
                    </List.Item>
                  </List>
                </List.Item>
              </List>
            </List.Item>
            <List.Item>
              <Text span>
                {platformName} {codeName} for the video above ({" "}
                <Anchor href={deliverable.liveContentUrl} target="_blank">
                  {deliverable.liveContentUrl}
                </Anchor>
                ), valid{" "}
                {deliverable.usageRightsInPerpetuity
                  ? "in perpetuity"
                  : `for at least ${deliverable.usageRightsDays} days`}
                .
              </Text>
              <List type="unordered" spacing={DEFAULT_LIST_SPACING}>
                <List.Item>
                  <Text span>
                    The {deliverable.usageRightsDays} day period should begin on the day you submit
                    your deliverables. Please do not general a code and then submit your
                    deliverables on a later day. Generate the code and immediately submit it.
                  </Text>
                </List.Item>
                {helpLink && (
                  <List.Item>
                    <Text span>{helpLink}</Text>
                  </List.Item>
                )}
              </List>
            </List.Item>
          </List>
        </Container>
      </Box>
      <Box p={DEFAULT_SECTION_PADDING}>
        {/* {index === null ? header("Timeline", 3) : */ header("Timeline", 4)}
        <Container mx="xs">
          <List type="unordered" spacing={DEFAULT_LIST_SPACING}>
            <List.Item>
              <Text span>
                {creatorFirstName.length > 0 ? creatorFirstName : deliverable.creatorHandle} submits
                required deliverables by:{" "}
                <Text span fw={FW_BOLD}>
                  {deliverable.timeline.liveDate.toDateString()}
                </Text>
              </Text>
            </List.Item>
          </List>
        </Container>
      </Box>
    </>
  );
};

const DeliverablesSection = ({
  sortOrder,
  deliverables,
  creatorFirstName,
}: {
  sortOrder: number[];
  deliverables: Deliverable[];
  creatorFirstName: string;
}) => {
  return (
    <DeliverableDetails
      deliverable={deliverables[sortOrder[0]]}
      creatorFirstName={creatorFirstName}
    />
  );
};

/**
 * Most of this is shared with UGC campaigns, but is duplicated here to make future specialization easier.
 */
const UsageRightsSection = ({
  brandName,
  platform,
}: {
  brandName: string;
  platform: SupportedPlatform;
}) => {
  const platformName = SUPPORTED_PLATFORMS_TO_DISPLAY_NAMES[platform];
  let codeName = "";
  let helpLink = null;
  switch (platform) {
    case SupportedPlatform.INSTAGRAM:
      codeName = "partnership ad code";
      helpLink =
        "https://help.1stcollab.com/en/articles/9493801-i-m-being-asked-to-submit-an-instagram-partnership-ad-code-how-do-i-do-that";
      break;
    case SupportedPlatform.TIKTOK:
      codeName = "spark code";
      helpLink =
        "https://help.1stcollab.com/en/articles/9493798-i-m-being-asked-to-submit-a-tiktok-spark-code-how-do-i-do-that";
      break;
    default:
      codeName = "partnership code";
      break;
  }

  return (
    <Box p={DEFAULT_SECTION_PADDING}>
      {header("Usage Rights", 3)}
      <Container mx="xs">
        <List type="unordered" spacing={DEFAULT_LIST_SPACING}>
          <List.Item>
            <Text span>
              &quot;Usage rights&quot; gives {brandName} the right to re-use your video. (Most
              often, they&apos;ll use the video in and/or recut it for social media paid ads).
              Don&apos;t worry; you still retain ownership of the video! What you&apos;re doing is
              granting {brandName} a license to use, distribute and display your video for
              promotional purposes across any media. This license is:
            </Text>
            <List withPadding type="unordered" spacing={DEFAULT_LIST_SPACING}>
              <List.Item>
                <Text span>
                  Worldwide (meaning: they can use your content anywhere in the world),
                </Text>
              </List.Item>
              <List.Item>
                <Text span>
                  Non-exclusive (meaning: you can still use the content or let other people use it
                  too-{brandName} doesn&apos;t have sole rights), and
                </Text>
              </List.Item>
              <List.Item>
                <Text span>
                  Royalty-free (meaning: {brandName} doesn&apos;t have to pay you extra money every
                  time they use the content).
                </Text>
              </List.Item>
            </List>
          </List.Item>
          <List.Item>
            <Text span>
              {brandName} also has the right to edit, modify, crop, or adapt the video as needed,
              provided that these edits do not misrepresent your original intent or opinion.
            </Text>
          </List.Item>
          <List.Item>
            <Text span> To make this all happen:</Text>
            <List withPadding type="unordered" spacing={DEFAULT_LIST_SPACING}>
              <List.Item>
                <Text span>The copy of the video that you upload will be sent to {brandName}.</Text>
              </List.Item>
              <List.Item>
                <Text span>
                  The {platformName} {codeName} that you submit will also be sent to {brandName}.
                  They&apos;ll use this code to boost your video on {platformName} and run it as an
                  ad; for more on what that means, check out{" "}
                  <Anchor href={helpLink} target="_blank">
                    this article
                  </Anchor>
                  .
                </Text>
              </List.Item>
            </List>
          </List.Item>
        </List>
      </Container>
    </Box>
  );
};

const UsageRightsCampaignContract = ({
  contractStatus,
  creatorFirstName,
  brandName,
  contractAmount,
  bonusAmount,
  bonusCondition,
  deliverables,
  updateDeliverableTimeline,
  addendum,
  repeatContract,
  repeatInterval,
  repeatFrequency,
  repeatType,
  repeatNumOccurrences,
}: {
  contractStatus: ContractStatus;
  creatorFirstName: string;
  brandName: string;
  contractAmount: number;
  bonusAmount: number;
  bonusCondition: string;
  deliverables: Deliverable[];
  updateDeliverableTimeline: (deliverableId: string, timeline: DeliverableTimeline) => void;
  addendum: string;
  repeatContract?: boolean;
  repeatInterval?: number;
  repeatFrequency?: RecurrenceFrequency;
  repeatType?: RecurrenceType;
  repeatNumOccurrences?: number;
}) => {
  if (deliverables === null || deliverables.length === 0) {
    return null;
  }
  // Sort Order for Deliverables
  const sortOrder = useRef(
    Array.from({ length: deliverables.length }, (_, i) => i).sort((a, b) => {
      const aLiveDate = deliverables[a].timeline.liveDate;
      const bLiveDate = deliverables[b].timeline.liveDate;

      if (aLiveDate < bLiveDate) {
        return -1;
      } else if (aLiveDate > bLiveDate) {
        return 1;
      }
      return 0;
    }),
  );

  return (
    <Box>
      {repeatContract && (
        <RepeatCondition
          repeatInterval={repeatInterval}
          repeatFrequency={repeatFrequency}
          repeatType={repeatType}
          repeatNumOccurrences={repeatNumOccurrences}
        />
      )}
      <UsageRightsCampaignSection
        contractStatus={contractStatus}
        brandName={brandName}
        sortOrder={sortOrder.current}
        deliverables={deliverables}
        updateDeliverableTimeline={updateDeliverableTimeline}
      />
      <SpacedDivider />
      <DeliverablesSection
        sortOrder={sortOrder.current}
        deliverables={deliverables}
        creatorFirstName={creatorFirstName}
      />
      <SpacedDivider />
      <TermsSection />
      <SpacedDivider />
      <CompensationSection
        contractAmount={contractAmount}
        bonusAmount={bonusAmount}
        bonusCondition={bonusCondition}
      />
      <SpacedDivider />
      <UsageRightsSection brandName={brandName} platform={deliverables[0].platform} />
      <SpacedDivider />
      {addendum !== null && addendum.length > 0 && (
        <>
          <SpacedDivider />
          <Addendum addendum={addendum} />
        </>
      )}
    </Box>
  );
};

export default UsageRightsCampaignContract;
