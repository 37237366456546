import React, { useState } from "react";

import { ActionIcon, Box, Card, Center, Collapse, Container, Grid, Paper } from "@mantine/core";

import { useDisclosure } from "@mantine/hooks";

import {
  IconChevronDown,
  IconDisabled,
  IconFloatNone,
  IconLine,
  IconLineDashed,
} from "@tabler/icons-react";

export function Chevron({ opened, toggle }: { opened: boolean; toggle?: () => void }) {
  return (
    <ActionIcon variant="transparent" onClick={toggle} color="gray">
      <IconChevronDown
        size="1rem"
        style={{
          transform: opened ? "rotate(180deg)" : "rotate(0deg)",
          transition: "transform 0.3s ease",
        }}
      />
    </ActionIcon>
  );
}

export default function CollapsibleCard({
  isOpen,
  header,
  controls,
  content,
  disableHoverStyle,
  lazyLoadContent,
  customOpened,
  customToggle,
  disabledCollapse = false,
}: {
  isOpen: boolean;
  header: React.ReactNode;
  controls: React.ReactNode;
  content: React.ReactNode;
  disableHoverStyle?: boolean;
  lazyLoadContent?: boolean;
  customOpened?: boolean;
  customToggle?: () => void;
  disabledCollapse?: boolean;
}) {
  const [opened, { toggle }] = useDisclosure(isOpen);
  const [hover, setHover] = useState(false);

  const openedToUse = customOpened === undefined ? opened : customOpened;
  const toggleToUse = customToggle === undefined ? toggle : customToggle;

  return (
    <Card
      p={0}
      radius="md"
      withBorder
      bg={!disableHoverStyle && (openedToUse || hover) ? "#F8F9FA" : ""}>
      <Grid>
        <Grid.Col span="content">
          <Center
            h="100%"
            w="100%"
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            onClick={toggleToUse}
            style={{ cursor: "pointer" }}>
            <Box pl="sm" mr="-xs">
              {disabledCollapse ? (
                <div style={{ width: "24px", height: "24px" }} />
              ) : (
                <Chevron opened={openedToUse} toggle={toggleToUse} />
              )}{" "}
            </Box>
          </Center>
        </Grid.Col>
        <Grid.Col span="auto">
          <Paper
            py="sm"
            shadow="none"
            radius={0}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            onClick={toggleToUse}
            style={{ cursor: "pointer" }}
            bg={!disableHoverStyle && (openedToUse || hover) ? "#F8F9FA" : ""}>
            {header}
          </Paper>
        </Grid.Col>

        <Grid.Col span="content">
          {controls && (
            <Center h="100%" w="100%">
              <Paper
                ml="-xs"
                pr="sm"
                bg={!disableHoverStyle && (openedToUse || hover) ? "#F8F9FA" : ""}
                shadow="none"
                radius={0}>
                {controls}
              </Paper>
            </Center>
          )}
        </Grid.Col>
      </Grid>
      {!disabledCollapse && (
        <Collapse in={openedToUse}>
          <Container px="sm" pb="sm">
            {lazyLoadContent && !openedToUse ? null : content}
          </Container>
        </Collapse>
      )}
    </Card>
  );
}
