import React, { useState } from "react";

import {
  Alert,
  Anchor,
  Box,
  Container,
  Divider,
  Flex,
  HoverCard,
  Indicator,
  List,
  Space,
  Stack,
  Text,
} from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { IconCalendar, IconRepeat } from "@tabler/icons-react";

import {
  contractPendingSignature,
  ContractStatus,
  SHORT_FORM_FORMATS,
} from "components/contracts/common/Common";
import DeliverableTimeline from "components/contracts/contract/DeliverableTimeline";
import header from "components/contracts/contract/TitleHeader";
import Deliverable from "components/contracts/models/Deliverable";

import { RecurrenceFrequency, RecurrenceType } from "models/Common";

export const DEFAULT_SECTION_PADDING = 1;
const DEFAULT_SECTION_SPACING = "xs";
export const DEFAULT_LIST_SPACING = 1;
export const FW_BOLD = 600;

export function SpacedDivider() {
  return (
    <Box mt={DEFAULT_SECTION_SPACING}>
      <Divider />
    </Box>
  );
}

export function DateSelectorInfo({ brandName }: { brandName: string }) {
  return (
    <>
      <Text>
        Choose a{" "}
        <Text span fw="bold">
          Live Date
        </Text>{" "}
        that works best for you.{" "}
      </Text>
      <Space my="sm" />
      <Text>
        If you&apos;re not sure yet, don&apos;t worry, just use your best estimate! Your{" "}
        <Text span fw="bold">
          Live Date
        </Text>{" "}
        helps{" "}
        <Text span fw="bold">
          {brandName}
        </Text>{" "}
        plan their campaign, but it&apos;s not set in stone.
      </Text>
    </>
  );
}

export function RepeatCondition({
  repeatInterval,
  repeatFrequency,
  repeatType,
  repeatNumOccurrences,
}: {
  repeatInterval: number;
  repeatFrequency: RecurrenceFrequency;
  repeatType: RecurrenceType;
  repeatNumOccurrences: number;
}) {
  if (
    !repeatInterval ||
    !repeatFrequency ||
    !repeatType ||
    ((!repeatNumOccurrences || repeatNumOccurrences <= 1) && repeatType === RecurrenceType.FIXED)
  ) {
    return null;
  }

  let repeatTypeText = null;
  if (repeatType === RecurrenceType.FIXED) {
    repeatTypeText = (
      <Text span>
        {" "}
        for a total of{" "}
        <Text span fw="500">
          {repeatNumOccurrences - 1} additional occurrence
          {repeatNumOccurrences - 1 === 1 ? "" : "s"}
        </Text>
      </Text>
    );
  }

  return (
    <Alert
      variant="light"
      color="blue"
      title="Recurring Partnership"
      radius="md"
      icon={<IconRepeat size="1rem" />}>
      <Text>
        This partnership will repeat every{" "}
        <Text span fw="500">
          {repeatInterval === 1 ? "" : `${repeatInterval} `}
          {repeatFrequency}
          {repeatInterval > 1 ? "s" : ""}
        </Text>
        {repeatTypeText}.
      </Text>
    </Alert>
  );
}

export function SingleDeliverableLiveDate({
  contractStatus,
  brandName,
  liveDateHeading,
  deliverable,
  updateDeliverableTimeline,
}: {
  contractStatus: ContractStatus;
  brandName: string;
  liveDateHeading: string;
  deliverable: Deliverable;
  updateDeliverableTimeline: (deliverableId: string, newTimeline: DeliverableTimeline) => void;
}) {
  const [clicked, setClicked] = useState(false);

  const handleLiveDateChange = (newLiveDate: Date) => {
    const newTimeline = deliverable.timeline.adjustTimeline({
      baseLiveDate: newLiveDate,
      isShortForm: SHORT_FORM_FORMATS.includes(deliverable.format),
      staticLiveDateWindow: true,
    });
    updateDeliverableTimeline(deliverable.id, newTimeline);
  };

  const editableLiveDate =
    deliverable.timeline.editableLiveDate &&
    contractPendingSignature(contractStatus) &&
    updateDeliverableTimeline;

  if (!editableLiveDate) {
    return (
      <Text>
        <Text span fw={FW_BOLD}>
          {liveDateHeading}
        </Text>
        <Text span>{deliverable.timeline.liveDate.toDateString()}</Text>
      </Text>
    );
  }

  return (
    <Flex gap="xs" align="center">
      <Text span fw={FW_BOLD}>
        Live Date:
      </Text>
      <HoverCard width={400} shadow="md" withArrow position="top">
        <HoverCard.Target>
          <Indicator color="red" size={7} processing disabled={clicked}>
            <DatePickerInput
              disabled={!deliverable.timeline.editableLiveDate}
              size="xs"
              firstDayOfWeek={0}
              minDate={deliverable.timeline.minLiveDate}
              maxDate={deliverable.timeline.maxLiveDate}
              leftSection={<IconCalendar size="1rem" />}
              value={deliverable.timeline.liveDate}
              onChange={handleLiveDateChange}
              onClick={() => setClicked(true)}
            />
          </Indicator>
        </HoverCard.Target>
        <HoverCard.Dropdown>
          <DateSelectorInfo brandName={brandName} />
        </HoverCard.Dropdown>
      </HoverCard>
    </Flex>
  );
}

export function TermsSection() {
  return (
    <Box p={DEFAULT_SECTION_PADDING}>
      {header("Terms", 3)}
      <Text>
        By agreeing to this brand sponsorship, you agree to the{" "}
        <Anchor inline href="https://www.1stcollab.com/terms-of-service">
          1stCollab Terms & Conditions
        </Anchor>
      </Text>
    </Box>
  );
}

export function BonusCondition({
  bonusAmount,
  bonusCondition,
}: {
  bonusAmount: number;
  bonusCondition: string;
}) {
  if (!bonusAmount || !bonusCondition) {
    return null;
  }
  return (
    <Text>
      Additional bonus of{" "}
      <Text span fw="500">
        {bonusAmount.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        })}{" "}
        US
      </Text>{" "}
      if {bonusCondition}, to be paid within seven (7) days after the bonus condition is met.
    </Text>
  );
}

export function CompensationSection({
  contractAmount,
  bonusAmount,
  bonusCondition,
}: {
  contractAmount: number;
  bonusAmount: number;
  bonusCondition: string;
}) {
  return (
    <Box p={DEFAULT_SECTION_PADDING}>
      {header("Compensation", 3)}
      <Container mx="xs">
        <List type="unordered" spacing={DEFAULT_LIST_SPACING}>
          <List.Item>
            <HoverCard width={280} shadow="md">
              <Text>
                <Text span fw={FW_BOLD}>
                  {contractAmount.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })}{" "}
                  US
                </Text>{" "}
                flat-fee, to be{" "}
                <HoverCard.Target>
                  <Anchor>paid</Anchor>
                </HoverCard.Target>{" "}
                within seven (7) days after all deliverables are live.
              </Text>
              <HoverCard.Dropdown>
                <Stack gap="xs">
                  <Text fw={FW_BOLD}>How will I get paid?</Text>
                  <Text>
                    Through 1stCollab&apos;s partnership with Stripe, creators on our platform
                    receive payments with no transaction fees in USD, or 1% for non-USD, compared to
                    ~2-5% through other processors like PayPal.
                  </Text>
                  <Text>
                    Submitting your payment details via Stripe is quick and secure, and takes only a
                    few minutes!{" "}
                  </Text>
                </Stack>
              </HoverCard.Dropdown>
            </HoverCard>
          </List.Item>
          {bonusAmount > 0 && (
            <List.Item>
              <BonusCondition bonusAmount={bonusAmount} bonusCondition={bonusCondition} />
            </List.Item>
          )}
        </List>
      </Container>
    </Box>
  );
}

export function Addendum({ addendum }: { addendum: string }) {
  if (!addendum || addendum.length === 0) {
    return null;
  }
  return (
    <Box p={DEFAULT_SECTION_PADDING}>
      {header("Addendum", 3)}
      <Flex mt="-md">
        <div dangerouslySetInnerHTML={{ __html: addendum }} />
      </Flex>
    </Box>
  );
}

export default null;
