import moment from "moment";
import Holidays from "date-holidays";

export const holidays = new Holidays("US");
export enum DateTypes {
  DAYS = "days",
  WEEKS = "weeks",
  MONTHS = "months",
  YEARS = "years",
}

function adjustForwardToNonHolidayBusinessDay(date: Date): Date {
  const adjustedDate = moment(new Date(date));

  while (
    adjustedDate.weekday() === 0 ||
    adjustedDate.weekday() === 6 ||
    holidays.isHoliday(adjustedDate.toDate())
  ) {
    if (adjustedDate.weekday() === 0) {
      // Sunday
      adjustedDate.add(1, "days");
    } else if (adjustedDate.weekday() === 6) {
      // Saturday
      adjustedDate.add(2, "days");
    } else if (holidays.isHoliday(adjustedDate.toDate())) {
      // Holiday
      adjustedDate.add(1, "days");
    }
  }

  return adjustedDate.toDate();
}

function adjustBackwardToNonHolidayBusinessDay(date: Date): Date {
  const adjustedDate = moment(new Date(date));

  while (
    adjustedDate.weekday() === 0 ||
    adjustedDate.weekday() === 6 ||
    holidays.isHoliday(adjustedDate.toDate())
  ) {
    if (adjustedDate.weekday() === 0) {
      // Sunday
      adjustedDate.subtract(2, "days");
    } else if (adjustedDate.weekday() === 6) {
      // Saturday
      adjustedDate.subtract(1, "days");
    } else if (holidays.isHoliday(adjustedDate.toDate())) {
      // Holiday
      adjustedDate.subtract(1, "days");
    } else {
      break;
    }
  }

  return adjustedDate.toDate();
}

export function addNonHolidayBusinessDays(date: Date, numDays: number): Date {
  if (numDays <= 0) {
    return date;
  }
  let adjustedDate = moment(new Date(date));

  for (let i = 0; i < numDays; i += 1) {
    adjustedDate = moment(
      adjustForwardToNonHolidayBusinessDay(adjustedDate.add(1, "days").toDate()),
    );
  }

  return adjustedDate.toDate();
}

function subtractNonHolidayBusinessDays(date: Date, numDays: number): Date {
  if (numDays <= 0) {
    return date;
  }
  let adjustedDate = moment(new Date(date));

  for (let i = 0; i < numDays; i += 1) {
    adjustedDate = moment(
      adjustBackwardToNonHolidayBusinessDay(adjustedDate.subtract(1, "days").toDate()),
    );
  }

  return adjustedDate.toDate();
}

export function computeNonHolidayBusinessDayAdjustment(
  date: Date,
  adjustment: number,
  adjustmentType: DateTypes,
): Date {
  if (adjustment === 0) {
    return date;
  }

  // Handle Days
  if (adjustmentType === DateTypes.DAYS) {
    if (adjustment > 0) {
      return addNonHolidayBusinessDays(date, adjustment);
    }
    return subtractNonHolidayBusinessDays(date, -adjustment);
  }

  // Handle Weeks, Months, Years
  const adjustedDate = moment(new Date(date));
  if (adjustment > 0) {
    return adjustForwardToNonHolidayBusinessDay(
      adjustedDate.add(adjustment, adjustmentType).toDate(),
    );
  }
  return adjustBackwardToNonHolidayBusinessDay(
    adjustedDate.subtract(-adjustment, adjustmentType).toDate(),
  );
}

export function addDays(date: Date, numDays: number): Date {
  const adjustedDate = moment(new Date(date));
  return adjustedDate.add(numDays, "days").toDate();
}

export function getDaysBetweenDates(startDate: Date, endDate: Date): number {
  const adjustedStartDate = moment(new Date(startDate));
  const adjustedEndDate = moment(new Date(endDate));
  return adjustedEndDate.diff(adjustedStartDate, "days");
}

export function toISODateString(date: Date): string {
  return moment(date).format("YYYY-MM-DD");
}

export function toISOString(date: Date): string {
  return moment(date).format("YYYY-MM-DD HH:mm:ss");
}

export function fromISODateString(date: string): Date {
  if (date === null || date === undefined || date === "") {
    return null;
  }
  return moment(date).toDate();
}

export function toLongTimeDateString(date: Date): string {
  return moment(date).format("h:mm A [on] dddd, MMMM D, YYYY");
}

export function toLongDateTimeString(date: Date): string {
  return moment(date).format("dddd, MMMM D, YYYY [at] h:mm A");
}

export function toLongDateTimeStringNoDay(date: Date): string {
  return moment(date).format("MMMM D, YYYY [at] h:mm A");
}

export function toPSTDateTimeString(date: Date): string {
  return moment(date).tz("America/Los_Angeles").format("MMM D, YYYY h:mm A z");
}

export function toLongDateString(date: Date): string {
  return moment(date).format("dddd, MMMM D, YYYY");
}

export function toShortDateString(date: Date): string {
  return moment(date).format("MM/DD/YY");
}

export function toMediumDateString(date: Date): string {
  return moment(date).format("ddd, MMM D, YYYY");
}

export function timeAgo(date: Date): string {
  const now = new Date();
  const diffInSeconds = Math.floor((now.getTime() - date.getTime()) / 1000);

  if (diffInSeconds < 60) {
    return `${diffInSeconds} seconds ago`;
  }

  const diffInMinutes = Math.floor(diffInSeconds / 60);
  if (diffInMinutes < 60) {
    return `${diffInMinutes} minute${diffInMinutes > 1 ? "s" : ""} ago`;
  }

  const diffInHours = Math.floor(diffInMinutes / 60);
  if (diffInHours < 24) {
    return `${diffInHours} hour${diffInHours > 1 ? "s" : ""} ago`;
  }

  const diffInDays = Math.floor(diffInHours / 24);
  return `${diffInDays} day${diffInDays > 1 ? "s" : ""} ago`;
}

export function getFormattedTimestamp(date: Date): string {
  const now = new Date();
  const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

  const isToday = (a: Date, b: Date): boolean => a.toDateString() === b.toDateString();

  const isYesterday = (a: Date, b: Date): boolean => {
    const yesterday = new Date(b);
    yesterday.setDate(b.getDate() - 1);
    return a.toDateString() === yesterday.toDateString();
  };

  const get12HourTime = (d: Date): string => {
    const hours = d.getHours();
    const minutes = d.getMinutes();
    const period = hours >= 12 ? "pm" : "am";
    const adjustedHour = hours % 12 || 12;
    return `${adjustedHour}:${minutes.toString().padStart(2, "0")}${period}`;
  };

  if (isToday(date, now)) {
    return `Today ${get12HourTime(date)}`;
  } else if (isYesterday(date, now)) {
    return `Yesterday ${get12HourTime(date)}`;
  } else if (now.valueOf() - date.valueOf() <= 7 * 24 * 60 * 60 * 1000) {
    // Less than a week ago
    return `${days[date.getDay()]} ${get12HourTime(date)}`;
  }

  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  return `${days[date.getDay()]}, ${
    monthNames[date.getMonth()]
  } ${date.getDate()} at ${get12HourTime(date)}`;
}

export function toFormattedMonthYearString(date: Date): string {
  return moment(date).utc().format("MMMM YYYY");
}

export function isValidTimestamp(timestamp: string): boolean {
  // Regular expression to match the timestamp formats:
  // - hh:mm:ss or mm:ss
  // - Allows omission of the first digit if it's a zero
  const regex = /^(?:(?:[0-1]?[0-9]|2[0-3]):)?(?:[0-5]?[0-9]):[0-5][0-9]$/;

  return regex.test(timestamp);
}

export function timestampToSeconds(timestamp: string): number {
  if (!isValidTimestamp(timestamp)) {
    return null;
  }

  return timestamp.split(":").reduce((acc, time) => 60 * acc + +time, 0);
}

export function secondsToTimestamp(seconds: number): string {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  // Only show hours if it's greater than 0
  if (hours > 0) {
    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${remainingSeconds.toString().padStart(2, "0")}`;
  }

  return `${minutes.toString().padStart(2, "0")}:${remainingSeconds.toString().padStart(2, "0")}`;
}

export const getUTCDate = (dateString: string) => {
  const date = new Date(dateString);
  const day = String(date.getUTCDate()).padStart(2, "0");
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const month = monthNames[date.getUTCMonth()];
  const year = date.getUTCFullYear();
  return `${month} ${day}, ${year}`;
};

export default null;
