import React, { useEffect, useRef, useState } from "react";
import { Group, Loader, Modal, Title, Tooltip } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconInfoCircle } from "@tabler/icons-react";
import { Box, Container, Datapoint, Text, SelectList } from "gestalt";
import "gestalt/dist/gestalt.css";

import { API_URL } from "configs/Configs";
import { User } from "firebase/auth";
import { createRequestWithFirebaseToken, handleResult } from "utils/ApiUtils";
import Chart, { ChartData } from "chart.js/auto";

import Spacer from "components/Spacer";
import { getAbbreviatedNumber } from "utils/AnalyticsUtils";
import { MediaEntry } from "components/MediaBlockCard";
import Video from "components/creator_lists/unified_creator_rep/Video";

const GET_CAMPAIGN_METRICS_URL = `${API_URL}/api/campaigns/campaign_metrics/`;
const GET_TOP_CONTENT_URL = `${API_URL}/api/campaigns/top_content/`;

const VIDEO_REP_HEIGHT = 386;
const VIDEO_REP_WIDTH = 217.5;

// TODO(andrew): move all of the model schemas out of components/
type MetricReport = {
  // Both the Daily and Weekly metric reports follow the same schema
  views: number;
  likes: number;
  comments: number;
  shares: number;
  saves: number;
  clicks: number;
  videos: number;
  // YT
  yt_views: number;
  yt_likes: number;
  yt_comments: number;
  yt_saves: number;
  yt_videos: number;
  // TT
  tt_views: number;
  tt_likes: number;
  tt_comments: number;
  tt_shares: number;
  tt_saves: number;
  tt_videos: number;
  // IG
  ig_views: number;
  ig_likes: number;
  ig_comments: number;
  ig_shares: number;
  ig_videos: number;
};

type CampaignMetricsResponse = {
  success?: boolean;
  message?: string;
  metrics?: MetricReport;
  // This is used for graphing
  metricPoints?: Record<string, MetricReport>;
  // This is used for the spend graph, mapping date to spent on that date
  spendPoints?: Record<string, number>;
  // This is used for the CPM graph
  cpmPoints?: Record<string, number>;
  cpcPoints?: Record<string, number>;
};

const getCampaignMetrics = async (
  campaignId: number,
  adGroupId?: number,
  platform?: string,
  metricTime?: string,
  clicksOnly = false,
  abortController: AbortController = new AbortController(),
) => {
  // API request to get campaign metrics
  const requestUrl = new URL(GET_CAMPAIGN_METRICS_URL);
  requestUrl.searchParams.append("campaignId", campaignId.toString());
  requestUrl.searchParams.append("clicks_only", clicksOnly.toString());
  if (adGroupId != null) {
    requestUrl.searchParams.append("ad_group_id", adGroupId.toString());
  }
  if (platform != null) {
    requestUrl.searchParams.append("platform", platform);
  }
  if (metricTime != null) {
    requestUrl.searchParams.append("range", metricTime);
  }
  const request = await createRequestWithFirebaseToken({ url: requestUrl });
  const response = await handleResult(request, abortController);
  return response;
};

const getTopContent = async (
  campaignId: number,
  date: string,
  adGroupId?: number,
  platform?: string,
  abortController: AbortController = new AbortController(),
) => {
  // API request to get top campaign content for a given day
  const requestUrl = new URL(GET_TOP_CONTENT_URL);
  requestUrl.searchParams.append("campaignId", campaignId.toString());
  if (adGroupId != null) {
    requestUrl.searchParams.append("ad_group_id", adGroupId.toString());
  }
  if (platform != null) {
    requestUrl.searchParams.append("platform", platform);
  }
  requestUrl.searchParams.append("date", date);
  requestUrl.searchParams.append("num", "10");

  const request = await createRequestWithFirebaseToken({ url: requestUrl });
  const response = await handleResult(request, abortController);
  return response;
};

const fetchCampaignMetrics = async (
  campaignId: number,
  setCampaignMetrics: (campaignMetrics: CampaignMetricsResponse) => void,
  adGroupId?: number,
  platform?: string,
  metricTime?: string,
  setMetricsLoaded?: (loaded: boolean) => void,
  clicksOnly = false,
  abortController: AbortController = new AbortController(),
) => {
  // Helper method to fetch campaign metrics, set the value, and trigger the callback
  setMetricsLoaded(false);
  setCampaignMetrics(null);
  const campaignMetrics = await getCampaignMetrics(
    campaignId,
    adGroupId,
    platform,
    metricTime,
    clicksOnly,
    abortController,
  );
  setCampaignMetrics(campaignMetrics);
  setTimeout(() => {
    setMetricsLoaded(true);
  }, 250);
  return campaignMetrics;
};

interface BarChartProps {
  data: ChartData<"bar">;
  title: string;
  clickHandler?: any;
}

interface LineChartProps {
  data: ChartData<"line">;
  title: string;
  ylabel?: string;
}

const CampaignMetricViewsChart: React.FC<BarChartProps> = ({ data, title, clickHandler }) => {
  const chartRef = useRef(null);
  const chartInstance = useRef(null);

  const localClickHandler = (evt: any) => {
    if (!clickHandler) {
      return;
    }
    const points = chartInstance.current.getElementsAtEventForMode(
      evt,
      "nearest",
      { intersect: true },
      true,
    );
    if (points.length) {
      const firstPoint = points[0];
      const label = chartInstance.current.data?.labels[firstPoint.index];
      clickHandler(label);
    } else {
      clickHandler(null);
    } // Else nothing to do
  };

  useEffect(() => {
    const chartContext = chartRef.current.getContext("2d");

    if (chartInstance.current) {
      chartInstance.current.destroy();
    }

    chartInstance.current = new Chart(chartContext, {
      type: "bar",
      data,
      options: {
        scales: {
          y: {
            type: "linear",
            position: "left",
            grid: {
              display: true,
              color: "rgba(0, 0, 0, 0.2)",
            },
            ticks: {
              precision: 0,
            },
            min: 0,
          },
          x: {
            grid: {
              display: false,
            },
          },
        },
        elements: {
          bar: {
            borderRadius: 3,
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          title: {
            display: title && title.length > 0,
            text: title,
            font: {
              size: 16,
              weight: "bold",
            },
          },
        },
        onClick: localClickHandler,
        onHover: (event, chartElement, chart) => {
          if (!clickHandler) {
            return;
          }
          const { canvas } = chart;
          canvas.style.cursor = chartElement[0] ? "pointer" : "default";
        },
      },
    });

    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
    };
  }, [data]);

  return <canvas ref={chartRef} />;
};

const CampaignMetricLineChart: React.FC<LineChartProps> = ({ data, title, ylabel }) => {
  const chartRef = useRef(null);
  const chartInstance = useRef(null);

  useEffect(() => {
    const chartContext = chartRef.current.getContext("2d");

    if (chartInstance.current) {
      chartInstance.current.destroy();
    }

    chartInstance.current = new Chart(chartContext, {
      type: "line",
      data,
      options: {
        scales: {
          y: {
            type: "linear",
            position: "left",
            title: {
              display: ylabel && true,
              text: ylabel,
            },
            grid: {
              display: true,
              color: "rgba(0, 0, 0, 0.2)",
            },
            ticks: {
              precision: 0,
            },
            min: 0,
          },
          x: {
            grid: {
              display: false,
            },
          },
        },
        elements: {
          line: {
            tension: 0.4,
            borderWidth: 2,
          },
          point: {
            radius: 3,
            hoverRadius: 5,
            hoverBackgroundColor: "white",
            borderColor: "white",
            borderWidth: 2,
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          title: {
            display: true,
            text: title,
            font: {
              size: 16,
              weight: "bold",
            },
          },
        },
      },
    });

    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
    };
  }, [data]);

  return <canvas ref={chartRef} />;
};

type MetricStyle = {
  borderColor: string;
  backgroundColor: string;
  hoverBackgroundColor: string;
  pointBackgroundColor: string;
};

type MetricStyles = {
  [key in keyof MetricReport]?: MetricStyle;
};

const convertMetricPointsToChartData = (
  metricPoints: Record<string, MetricReport>,
  metricsToGrab: [string, keyof MetricReport][],
  metricStyles: MetricStyles,
) => {
  if (!metricPoints) {
    return null;
  }
  const datasets = metricsToGrab.map(([label, metricKey]) => {
    const style = metricStyles[metricKey];
    return {
      label,
      data: Object.values(metricPoints).map((metricReport) => metricReport[metricKey]),
      ...style,
    };
  });

  return {
    labels: Object.keys(metricPoints),
    datasets,
  };
};

const convertMetricPointsVideosToChartData = (metricPoints: Record<string, MetricReport>) => {
  const metricsToGrab: [string, keyof MetricReport][] = [["Videos", "videos"]];
  const metricStyles: MetricStyles = {
    videos: {
      borderColor: "rgba(255, 99, 132, 1)",
      backgroundColor: "rgba(255, 99, 132, 0.6)",
      hoverBackgroundColor: "rgba(255, 99, 132, 1.0)",
      pointBackgroundColor: "red",
    },
  };
  return convertMetricPointsToChartData(metricPoints, metricsToGrab, metricStyles);
};

const convertMetricPointsViewsToChartData = (metricPoints: Record<string, MetricReport>) => {
  const metricsToGrab: [string, keyof MetricReport][] = [["Views", "views"]];
  const metricStyles: MetricStyles = {
    views: {
      borderColor: "rgba(0, 123, 255, 1)",
      backgroundColor: "rgba(0, 123, 255, 0.6)",
      hoverBackgroundColor: "rgba(0, 123, 255, 1.0)",
      pointBackgroundColor: "blue",
    },
  };
  return convertMetricPointsToChartData(metricPoints, metricsToGrab, metricStyles);
};

const convertMetricPointsClicksToChartData = (metricPoints: Record<string, MetricReport>) => {
  const metricsToGrab: [string, keyof MetricReport][] = [["Clicks", "clicks"]];
  const metricStyles: MetricStyles = {
    clicks: {
      borderColor: "rgba(0, 123, 255, 1)",
      backgroundColor: "rgba(0, 123, 255, 0.1)",
      hoverBackgroundColor: "rgba(0, 123, 255, 0.1)",
      pointBackgroundColor: "blue",
    },
  };
  return convertMetricPointsToChartData(metricPoints, metricsToGrab, metricStyles);
};

const convertSpendPointsToChartData = (spendPoints: Record<string, number>) => {
  const datasets = [
    {
      borderColor: "rgba(60, 179, 113, 1)",
      backgroundColor: "rgba(60, 179, 113, 0.6)",
      hoverBackgroundColor: "rgba(60, 179, 113, 1.0)",
      pointBackgroundColor: "green",
      label: "Spent",
      data: Object.values(spendPoints).map((v) => v / 100),
    },
  ];
  return {
    labels: Object.keys(spendPoints),
    datasets,
  };
};

const convertCpmPointsToChartData = (cpmPoints: Record<string, number>) => {
  const datasets = [
    {
      borderColor: "rgba(252, 186, 3, 1)",
      borderWidth: 1,
      backgroundColor: "rgba(252, 186, 3, 0.6)",
      hoverBackgroundColor: "rgba(252, 186, 3, 1.0)",
      pointBackgroundColor: "rgba(252, 186, 3, 0.6)",
      label: "CPM",
      title: {
        display: true,
        text: "$",
      },
      data: Object.values(cpmPoints).map((v) => v / 100),
    },
  ];
  return {
    labels: Object.keys(cpmPoints),
    datasets,
  };
};

const convertCpcPointsToChartData = (cpcPoints: Record<string, number>) => {
  const datasets = [
    {
      borderColor: "rgba(111, 3, 252, 1)",
      borderWidth: 1,
      backgroundColor: "rgba(111, 3, 252, 0.6)",
      hoverBackgroundColor: "rgba(111, 3, 252, 1.0)",
      pointBackgroundColor: "rgba(111, 3, 252, 0.6)",
      label: "CPC",
      data: Object.values(cpcPoints).map((v) => v / 100),
    },
  ];
  return {
    labels: Object.keys(cpcPoints),
    datasets,
  };
};

const filterMetricPoints = (metricPoints: Record<string, MetricReport>, filterDate: string) =>
  Object.keys(metricPoints).reduce((result, date) => {
    if (date > filterDate) {
      return { ...result, [date]: metricPoints[date] };
    }
    return result;
  }, {} as Record<string, MetricReport>);

const CampaignClicksChartView = ({
  campaignMetrics,
}: {
  campaignMetrics: CampaignMetricsResponse;
}) => {
  const { metricPoints } = campaignMetrics;
  if (metricPoints == null) {
    return null;
  }

  const clicksData = convertMetricPointsClicksToChartData(metricPoints);

  if (!clicksData || clicksData.labels.length === 0) {
    return null;
  }
  return <CampaignMetricViewsChart data={clicksData} title="Clicks per day" />;
};

const CampaignViewsChartView = ({
  campaignId,
  adGroupId,
  campaignMetrics,
  platform,
}: {
  campaignId: number;
  adGroupId?: number;
  campaignMetrics: CampaignMetricsResponse;
  platform: string;
}) => {
  const [modalDate, setModalDate] = useState<string>(null);
  const [topContent, setTopContent] = useState<MediaEntry[]>([]);
  const [opened, { open, close }] = useDisclosure(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const abortController = new AbortController();
    if (modalDate) {
      setIsLoading(true);
      getTopContent(campaignId, modalDate, adGroupId, platform, abortController)
        .then((response) => {
          const { success, topContent: incomingTopContent } = response;
          if (success) {
            setTopContent(incomingTopContent);
          }
        })
        .catch(() => {
          setTopContent([]);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setTopContent([]);
    }
    return () => {
      abortController.abort();
    };
  }, [modalDate]);

  const { metricPoints } = campaignMetrics;
  if (metricPoints == null) {
    return null;
  }

  const viewsData =
    platform === "instagram"
      ? convertMetricPointsViewsToChartData(filterMetricPoints(metricPoints, "2023-11-27"))
      : convertMetricPointsViewsToChartData(metricPoints);

  if (!viewsData || viewsData.labels.length === 0) {
    return null;
  }
  return (
    <>
      <Group preventGrowOverflow={false} gap={2}>
        <Title size={16}>Views per day</Title>
        <Tooltip
          multiline
          w={220}
          withArrow
          label={
            "The sum of all views from all prior live content on a given day. " +
            "Click a bar for the top videos for that day."
          }>
          <IconInfoCircle size={16} color="#868e96" />
        </Tooltip>
      </Group>
      <CampaignMetricViewsChart
        data={viewsData}
        title=""
        clickHandler={(newDate: string) => {
          setModalDate(newDate);
          if (newDate) {
            open();
          }
        }}
      />
      <Modal
        size="75rem"
        opened={opened}
        onClose={() => {
          close();
          setModalDate(null);
        }}
        title={<Title order={5}>Top performing videos for {modalDate}</Title>}
        centered>
        {isLoading && (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="100%"
            height="100%">
            <Loader />
          </Box>
        )}
        <Group>
          {topContent.map((mediaEntry) => (
            <Video
              key={`${mediaEntry.video_url}-${mediaEntry.video_id}`}
              video={mediaEntry}
              refetchThumbnails
              height={VIDEO_REP_HEIGHT}
              width={VIDEO_REP_WIDTH}
              includePlatform
            />
          ))}
        </Group>
      </Modal>
    </>
  );
};

const CampaignVideoChartView = ({
  campaignMetrics,
  platform,
}: {
  campaignMetrics: CampaignMetricsResponse;
  platform: string;
}) => {
  const { metricPoints } = campaignMetrics;
  if (metricPoints == null) {
    return null;
  }

  const viewsData =
    platform === "instagram"
      ? convertMetricPointsVideosToChartData(filterMetricPoints(metricPoints, "2023-11-27"))
      : convertMetricPointsVideosToChartData(metricPoints);

  if (!viewsData || viewsData.labels.length === 0) {
    return null;
  }
  return (
    <>
      <Group preventGrowOverflow={false} gap={2}>
        <Title size={16}>Videos per day</Title>
        <Tooltip
          multiline
          w={220}
          withArrow
          label="The number of videos that were published on a given day.">
          <IconInfoCircle size={16} color="#868e96" />
        </Tooltip>
      </Group>
      <CampaignMetricViewsChart data={viewsData} title="" />
    </>
  );
};

const CampaignSpendChartView = ({
  campaignMetrics,
}: {
  campaignMetrics: CampaignMetricsResponse;
}) => {
  const { spendPoints } = campaignMetrics;
  if (spendPoints == null) {
    return null;
  }

  const spendData = convertSpendPointsToChartData(spendPoints);

  if (!spendData || spendData.labels.length === 0) {
    return null;
  }
  return (
    <>
      <Group preventGrowOverflow={false} gap={2}>
        <Title size={16}>Spent per day</Title>
        <Tooltip
          multiline
          w={220}
          withArrow
          label={
            "The total spend on content for that day. Note that this can be later than a " +
            "video's live date, as we don’t charge until a contract is complete and all " +
            "deliverables have been verified. This chart is unaffected by the platform filter."
          }>
          <IconInfoCircle size={16} color="#868e96" />
        </Tooltip>
      </Group>
      <CampaignMetricViewsChart data={spendData} title="" />
    </>
  );
};

const CampaignCpmChartView = ({
  campaignMetrics,
}: {
  campaignMetrics: CampaignMetricsResponse;
}) => {
  const { cpmPoints } = campaignMetrics;
  if (cpmPoints == null) {
    return null;
  }

  const cpmData = convertCpmPointsToChartData(cpmPoints);

  if (!cpmData || cpmData.labels.length === 0) {
    return null;
  }
  return (
    <>
      <Group preventGrowOverflow={false} gap={2}>
        <Title size={16}>CPM</Title>
        <Tooltip
          multiline
          w={220}
          withArrow
          label="Aggregate cost per thousand (1000) views. This chart is unaffected by the platform filter.">
          <IconInfoCircle size={16} color="#868e96" />
        </Tooltip>
      </Group>
      <CampaignMetricLineChart data={cpmData} title="" ylabel="Dollars ($)" />
    </>
  );
};

const CampaignCpcChartView = ({
  campaignMetrics,
}: {
  campaignMetrics: CampaignMetricsResponse;
}) => {
  const { cpcPoints } = campaignMetrics;
  if (cpcPoints == null) {
    return null;
  }

  const cpcData = convertCpcPointsToChartData(cpcPoints);

  if (!cpcData || cpcData.labels.length === 0) {
    return null;
  }
  return (
    <>
      <Group preventGrowOverflow={false} gap={2}>
        <Title size={16}>CPC</Title>
        <Tooltip
          multiline
          w={220}
          withArrow
          label="Aggregate cost per click. This chart is unaffected by the platform filter.">
          <IconInfoCircle size={16} color="#868e96" />
        </Tooltip>
      </Group>
      <CampaignMetricLineChart data={cpcData} title="" ylabel="Dollars ($)" />
    </>
  );
};

const CampaignMetricReportView = ({
  campaignMetrics,
  platform = "all",
  showClicks = false,
  campaignTracksClicks = false,
}: {
  campaignMetrics: CampaignMetricsResponse;
  platform: string;
  showClicks?: boolean;
  campaignTracksClicks?: boolean;
}) => {
  const metricReport = campaignMetrics.metrics;

  const numVideos = metricReport.videos;
  const { views } = metricReport;
  const { likes } = metricReport;
  const { comments } = metricReport;
  const { saves } = metricReport;
  const linkClicks = metricReport.clicks;
  const { shares } = metricReport;

  const datapoints = [];

  if (showClicks) {
    if (metricReport.clicks != null && metricReport.clicks > 0) {
      datapoints.push(
        <Datapoint
          key="link-clicks"
          title="Link Clicks"
          value={getAbbreviatedNumber(linkClicks)}
        />,
      );
    }
  } else {
    if (metricReport.videos != null) {
      datapoints.push(
        <Datapoint
          key="total-videos"
          title="Videos Live"
          value={getAbbreviatedNumber(numVideos)}
        />,
      );
    }

    // if (metricReport.new_videos != null) {
    //   datapoints.push(
    //     <Datapoint
    //       key="new videos"
    //       title="New Videos"
    //       value={getAbbreviatedNumber(metricReport.new_videos)}
    //     />,
    //   );
    // }

    if (metricReport.views != null) {
      datapoints.push(
        <Datapoint key="total-views" title="Views" value={getAbbreviatedNumber(views)} />,
      );
    }

    if (metricReport.likes != null) {
      datapoints.push(
        <Datapoint key="total-likes" title="Likes" value={getAbbreviatedNumber(likes)} />,
      );
    }

    if (metricReport.comments != null) {
      datapoints.push(
        <Datapoint key="total-comments" title="Comments" value={getAbbreviatedNumber(comments)} />,
      );
    }

    // only include saves and shares if the campaign includes tiktok
    if ((platform === "all" || platform === "tiktok") && metricReport.views > 0) {
      if (metricReport.saves != null) {
        datapoints.push(
          <Datapoint key="total-saves" title="Saves" value={getAbbreviatedNumber(saves)} />,
        );
      }
      if (metricReport.shares != null) {
        datapoints.push(
          <Datapoint key="total-shares" title="Shares" value={getAbbreviatedNumber(shares)} />,
        );
      }
    }

    if (campaignTracksClicks && metricReport.clicks != null) {
      datapoints.push(
        <Datapoint
          key="link-clicks"
          title="Link Clicks"
          value={getAbbreviatedNumber(linkClicks)}
        />,
      );
    }
  }

  // take up 20% more width with each new stat
  const statsWidth = `${Math.min(datapoints.length * 20, 100)}%`;

  return (
    <>
      <Box display="flex" direction="row" width="100%" justifyContent="center">
        {datapoints.length > 0 ? (
          <Box display="flex" direction="row" width={statsWidth} justifyContent="between">
            {datapoints}
          </Box>
        ) : (
          <Box display="flex" direction="row" justifyContent="between">
            {datapoints}
          </Box>
        )}
      </Box>
      <Spacer height={32} />
    </>
  );
};

const TimeSelector = ({
  id,
  metricTime,
  setMetricTime,
}: {
  id: string;
  metricTime: string;
  setMetricTime: (metricTime: string) => void;
}) => (
  <SelectList
    id={id}
    label="Time Window"
    value={metricTime}
    onChange={(e) => {
      setMetricTime(e.value);
    }}
    size="lg">
    {[
      { label: "All", value: "all" },
      { label: "Last 7 Days", value: "7d" },
      { label: "Last 30 Days", value: "30d" },
      { label: "Last 90 Days", value: "90d" },
    ].map(({ label, value }) => (
      <SelectList.Option key={label} label={label} value={value} />
    ))}
  </SelectList>
);

const CampaignClicksMetricsView = ({
  user,
  campaignId,
  adGroupId,
}: {
  user: User;
  campaignId: number;
  adGroupId?: number;
}) => {
  const [campaignMetrics, setCampaignMetrics] = useState(null);
  const [metricTime, setMetricTime] = useState("all");
  const [metricsLoaded, setMetricsLoaded] = useState(false);
  const [hasClicks, setHasClicks] = useState(false);

  useEffect(() => {
    setMetricTime("all");
  }, [campaignId]);

  useEffect(() => {
    const abortController = new AbortController();
    fetchCampaignMetrics(
      campaignId,
      setCampaignMetrics,
      adGroupId,
      "all",
      metricTime,
      setMetricsLoaded,
      true,
      abortController,
    ).then((m) => {
      setHasClicks(m?.metrics?.clicks > 0);
    });
    return () => {
      abortController.abort();
      setHasClicks(false);
      // setMetricsLoaded(false);
    };
  }, [campaignId, adGroupId, metricTime]);

  return (
    hasClicks && (
      <Box alignItems="center" justifyContent="center" width="100%">
        <Box width="100%" height="100%">
          <Container>
            <>
              <Box display="flex" direction="column" alignItems="center">
                <Text size="400" weight="bold">
                  Clicks
                </Text>
                <Spacer height={16} />
                <TimeSelector
                  id="click-campaign-metrics-time"
                  metricTime={metricTime}
                  setMetricTime={setMetricTime}
                />
              </Box>

              {!metricsLoaded ? (
                <Box direction="column" display="flex" alignItems="center">
                  <Spacer height={16} />
                  <Loader />
                </Box>
              ) : (
                <Box direction="column" display="flex" alignItems="center">
                  <Spacer height={16} />
                  <CampaignMetricReportView
                    campaignMetrics={campaignMetrics}
                    platform="all"
                    showClicks
                  />
                  <Spacer height={20} />
                  <CampaignClicksChartView campaignMetrics={campaignMetrics} />
                  <Spacer height={6} />
                </Box>
              )}
            </>
          </Container>
        </Box>
      </Box>
    )
  );
};

const CampaignPlatformTimeSelector = ({
  platform,
  setPlatform,
  metricTime,
  setMetricTime,
}: {
  platform: string;
  setPlatform: (platform: string) => void;
  metricTime: string;
  setMetricTime: (metricTime: string) => void;
}) => (
  <Box display="flex" direction="row" justifyContent="center" flex-wrap="nowrap">
    <SelectList
      id="campaign-metrics-platform"
      label="Platform"
      value={platform}
      onChange={(e) => {
        setPlatform(e.value);
      }}
      size="lg">
      {[
        { label: "All", value: "all" },
        { label: "YouTube", value: "youtube" },
        { label: "TikTok", value: "tiktok" },
        { label: "Instagram", value: "instagram" },
      ].map(({ label, value }) => (
        <SelectList.Option key={label} label={label} value={value} />
      ))}
    </SelectList>
    <Spacer width={24} />
    <TimeSelector
      id="campaign-metrics-time"
      metricTime={metricTime}
      setMetricTime={setMetricTime}
    />
  </Box>
);

const CampaignMetricsView = ({
  campaignId,
  adGroupId,
  campaignMetrics,
  platform,
  setPlatform,
  metricTime,
  setMetricTime,
  showMetrics = false,
  metricsLoaded = false,
  campaignTracksClicks = false,
}: {
  campaignId: number;
  adGroupId?: number;
  campaignMetrics: CampaignMetricsResponse;
  platform: string;
  setPlatform: (platform: string) => void;
  metricTime: string;
  setMetricTime: (metricTime: string) => void;
  showMetrics?: boolean;
  metricsLoaded?: boolean;
  campaignTracksClicks?: boolean;
}) => {
  // Check if there is anything live
  if (campaignMetrics == null) {
    return null;
  }
  const metricReport = campaignMetrics.metrics;
  const isLive = metricReport != null;

  return (
    <Box>
      {isLive ? (
        <>
          <div />
          {showMetrics &&
            (!metricsLoaded ? (
              <Box direction="column" display="flex" alignItems="center">
                <Spacer height={16} />
                <Loader />
              </Box>
            ) : (
              <Box direction="column" display="flex" alignItems="center">
                <Spacer height={16} />
                <CampaignMetricReportView
                  campaignMetrics={campaignMetrics}
                  platform={platform}
                  campaignTracksClicks={campaignTracksClicks}
                />
                <Spacer height={20} />
                <CampaignViewsChartView
                  campaignId={campaignId}
                  adGroupId={adGroupId}
                  campaignMetrics={campaignMetrics}
                  platform={platform}
                />
                <Spacer height={6} />
                <CampaignVideoChartView campaignMetrics={campaignMetrics} platform={platform} />
                <Spacer height={6} />
                <CampaignSpendChartView campaignMetrics={campaignMetrics} />
                <Spacer height={6} />
                <CampaignCpmChartView campaignMetrics={campaignMetrics} />
                {campaignTracksClicks && (
                  <>
                    <Spacer height={6} />
                    <CampaignCpcChartView campaignMetrics={campaignMetrics} />
                  </>
                )}
              </Box>
            ))}
        </>
      ) : null}
    </Box>
  );
};

export const CampaignMetrics = ({
  campaignId,
  adGroupId,
  showMetrics = false,
  campaignTracksClicks = false,
}: {
  campaignId: number;
  adGroupId?: number;
  showMetrics?: boolean;
  campaignTracksClicks?: boolean;
}) => {
  const [campaignMetrics, setCampaignMetrics] = useState(null);
  // TODO(andrew): migrate this to the query param-based platform selector
  // and move the selector out of the CampaignMetricsView so the selector doesn't reload when the metrics reload
  const [metricPlatform, setMetricPlatform] = useState("all");
  const [metricTime, setMetricTime] = useState("30d");
  const [loaded, setLoaded] = useState(false);
  const [metricsLoaded, setMetricsLoaded] = useState(false);

  useEffect(() => {
    setMetricPlatform("all");
    setMetricTime("30d");
  }, [campaignId]);

  useEffect(() => {
    const abortController = new AbortController();
    fetchCampaignMetrics(
      campaignId,
      setCampaignMetrics,
      adGroupId,
      metricPlatform,
      metricTime,
      setMetricsLoaded,
      false,
      abortController,
    ).then(() => {
      setLoaded(true);
    });

    return () => {
      abortController.abort();
      // setLoaded(false);
      // setMetricsLoaded(false);
      // setCampaignMetrics(null);
    };
  }, [campaignId, adGroupId, metricPlatform, metricTime]);

  return (
    <Box alignItems="center" justifyContent="center" width="100%">
      <Box width="100%" height="100%">
        <Container>
          <Box display="flex" direction="row" justifyContent="center">
            <Text size="400" weight="bold">
              Campaign Metrics
            </Text>
          </Box>
          <Spacer height={16} />
          <Box display="flex" direction="row" justifyContent="center" flex-wrap="nowrap">
            <CampaignPlatformTimeSelector
              platform={metricPlatform}
              setPlatform={setMetricPlatform}
              metricTime={metricTime}
              setMetricTime={setMetricTime}
            />
          </Box>
          {!loaded ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="100%"
              height="100%">
              <Loader />
            </Box>
          ) : (
            <CampaignMetricsView
              campaignId={campaignId}
              adGroupId={adGroupId}
              campaignMetrics={campaignMetrics}
              platform={metricPlatform}
              setPlatform={setMetricPlatform}
              metricTime={metricTime}
              setMetricTime={setMetricTime}
              showMetrics={showMetrics}
              metricsLoaded={metricsLoaded}
              campaignTracksClicks={campaignTracksClicks}
            />
          )}
        </Container>
      </Box>
    </Box>
  );
};

export default CampaignMetrics;
